import { call, put, select, take } from 'redux-saga/effects';
import { channel } from 'redux-saga';

import { ctaApi } from '../../../../shared/api';
import { ActionTypeCTA, CTA, PopUp } from '@distribute/shared/types';
import { UpdateCTAImageResponseType } from '@distribute/shared/api-types/cta';

import { actions } from '../reducer';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { conversionKitModel } from '../index';
import { selectCTA, selectPopUp } from '../selectors';

import { checkIsDraftTemplate, getPageIdByContentId } from '../../lib';
import { templatesModel } from '../../../templates';
import { updateCtaDB } from './updateCtaDB';
const uploadFileChannel = channel<number>();

export function* updateCTA({
  payload: { documentContentId, callback, isRemoveImage, ...rest },
}: ReturnType<typeof conversionKitModel.actions.updateCTA>) {
  try {
    yield put(actions.setUpdateCTAIsLoading(true));

    const cta: CTA = yield select(selectCTA);

    yield put(
      actions.setCTA({
        ...cta,
        ...rest,
        imageUrl: isRemoveImage ? undefined : cta.imageUrl,
      })
    );

    if (isRemoveImage) {
      yield call(ctaApi.removeCTAImage, documentContentId);
    }

    if (rest.isActive && rest.actionType === ActionTypeCTA.POPUP) {
      const popUp: PopUp = yield select(selectPopUp);

      yield put(actions.setPopUp({ ...popUp, isActive: true }));
    }

    const pageId: string | undefined = yield call(
      getPageIdByContentId,
      documentContentId
    );

    const isDraftTemplate: boolean = yield call(
      checkIsDraftTemplate,
      documentContentId
    );

    if (!pageId && !isDraftTemplate) {
      yield put(templatesModel.actions.setIsTemplateSavedInEditor(false));
    } else {
      yield call(updateCtaDB, actions.updateCtaDB({ documentContentId }));
    }

    if (callback) {
      yield call(callback);
    }
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', `Failed to update CTA`)
      )
    );
  } finally {
    yield put(actions.setUpdateCTAIsLoading(false));
  }
}

export function* changeCTAImage({
  payload: { documentContentId, image, cb },
}: ReturnType<typeof actions.changeCTAImage>) {
  try {
    yield put(actions.setIsUploadImageLoading(true));

    const cta = (yield ctaApi.updateCTAImage(
      documentContentId,
      image,
      (progress) => uploadFileChannel.put(progress)
    )) as UpdateCTAImageResponseType;

    const ctaFromState: CTA = yield select(selectCTA);

    yield put(actions.setCTA({ ...ctaFromState, imageUrl: cta.imageUrl }));

    cb?.(cta.imageUrl as string, image.name);
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', `Failed to update CTA image`)
      )
    );
  } finally {
    yield put(actions.setIsUploadImageLoading(false));
    uploadFileChannel.put(0);
  }
}

export function* removeCTAImage({
  payload: { documentContentId },
}: ReturnType<typeof actions.removeCTAImage>) {
  try {
    yield put(actions.setIsRemoveCTAImageLoading(true));

    yield call(ctaApi.removeCTAImage, documentContentId);

    const ctaFromState: CTA = yield select(selectCTA);

    yield put(actions.setCTA({ ...ctaFromState, imageUrl: undefined }));
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', `Failed to remove CTA image`)
      )
    );
  } finally {
    yield put(actions.setIsRemoveCTAImageLoading(false));
  }
}

export function* watchUploadCTAImage() {
  while (true) {
    const progress = (yield take(uploadFileChannel)) as number;

    yield put(actions.setUploadCTAImageProgress(progress));
  }
}
