import { ConversionKitCalendarData } from './calendar';

export enum ActionTypePopUp {
  FORM = 'form',
  LINK = 'link',
  CALENDLY = 'calendly',
}

export type PopUp = ConversionKitCalendarData & {
  id: string;
  isActive: boolean;
  imageUrl?: string;
  title: string | null;
  description: string | null;
  actionType: ActionTypePopUp;
  isFormName: boolean;
  isFormPhone: boolean;
  isFormMessage: boolean;
  buttonLabelFormType?: string;
  buttonLabelLinkType?: string;
  buttonUrl?: string;
  isTriggerTime: boolean;
  triggerTimeDelay?: string;
  triggerTimeVisits?: string;
  isTriggerExit: boolean;
};

export type CollaborativePopUp = PopUp & {
  isTitleEnabled: boolean;
  isDescriptionEnabled: boolean;
  isImageEnabled: boolean;
};
