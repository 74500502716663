import { pagesModel } from '..';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { put, select } from 'redux-saga/effects';
import { pagesApi } from '../../../../shared/api/';
import { Page } from '@distribute/shared/types';

export function* deletePageBrandLogo() {
  try {
    const currentPage: Page = yield select(
      pagesModel.selectors.selectCurrentPageWithError
    );
    yield put(pagesModel.actions.setIsBrandLogoDeleting(true));
    yield pagesApi.deletePageBrandLogo(currentPage.id);
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to delete client logo')
      )
    );
  } finally {
    yield put(pagesModel.actions.setIsBrandLogoDeleting(false));
  }
}
