import { Doc, encodeStateAsUpdate, applyUpdate } from 'yjs';

export enum CollaborationEvent {
  Ping = 'ping',
  Pong = 'pong',
  LoadError = 'load-error',
  UpdateError = 'update-error',
  UpdateSuccess = 'update-success',
  AuthError = 'auth-error',
}

type PingAction = {
  type: CollaborationEvent.Ping;
  payload: number;
};
type PongAction = {
  type: CollaborationEvent.Pong;
  payload: number;
};
type LoadErrorAction = {
  type: CollaborationEvent.LoadError;
  payload: string;
};
type UpdateErrorAction = {
  type: CollaborationEvent.UpdateError;
  payload: string;
};
type UpdateSuccessAction = {
  type: CollaborationEvent.UpdateSuccess;
  payload: string;
};
type AuthErrorAction = {
  type: CollaborationEvent.AuthError;
  payload: string;
};

export type CollaborationAction =
  | PingAction
  | PongAction
  | LoadErrorAction
  | UpdateErrorAction
  | UpdateSuccessAction
  | AuthErrorAction;

export function isEmpty(doc: Doc, fieldName: string): boolean {
  return !doc.get(fieldName)._start && !doc.get(fieldName)._map.size;
}

export function merge(doc: Doc, documents: Doc | Array<Doc>): Doc {
  (Array.isArray(documents) ? documents : [documents]).forEach((document) => {
    applyUpdate(doc, encodeStateAsUpdate(document));
  });

  return doc;
}

export const COLLABORATION_COLORS_CONFIG = [
  '#FF4405',
  '#F63D68',
  '#D444F1',
  '#7A5AF8',
  '#6172F3',
  '#0BA5EC',
  '#15B79E',
  '#66C61C',
];

export function getRandomCollaborationColor() {
  return COLLABORATION_COLORS_CONFIG[
    Math.floor(Math.random() * COLLABORATION_COLORS_CONFIG.length)
  ];
}

export type CollaborationUserDetails = {
  id: string;
  name: string;
  color: string;
};

export function renderCollaborativeCursor(user: CollaborationUserDetails) {
  const cursor = document.createElement('span');

  cursor.classList.add('collaboration-cursor__caret');

  cursor.setAttribute('style', `outline-color: ${user.color}`);
  cursor.setAttribute('id', user.id);

  const label = document.createElement('div');

  label.classList.add('collaboration-cursor__label');

  label.setAttribute('style', `background-color: ${user.color}`);
  label.insertBefore(document.createTextNode(user.name), null);

  cursor.insertBefore(label, null);

  return cursor;
}

export function createWSAction<T>({
  payload,
  type,
}: {
  payload: T;
  type: CollaborationEvent;
}) {
  return JSON.stringify({
    type: type,
    payload,
  });
}
