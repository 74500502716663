import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editorSidebarModel } from '../../../../../features/editor-sidebar';
import { EditorSidebarConversionsPanelFooter } from './EditorSidebarConversionsPanelFooter';
import { Icon, ImageInput, Input, TabsGroup } from '../../../../../shared/ui';
import { POP_UP_TABS } from '../../../lib';
import { conversionKitModel } from '../../../../../features/conversion-kit';
import { boolean, object, string } from 'yup';
import {
  ActionTypeAlertBar,
  ActionTypeCTA,
  ActionTypePopUp,
  CalendarProvider,
  Page,
} from '@distribute/shared/types';
import { useController, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LabelToggle } from '../../../../../shared/ui/LabelToggle';
import { IconMap } from '../../../../../shared/sprite';
import { Label } from './Label';

import cn from 'classnames';
import { BehaviorSettingsItem } from './BehaviorSettingsItem';
import { CalendarForm } from './CalendarForm';
import { PopupTitleAndDescriptionFields } from './PopupTitleAndDescriptionFields';
import { LINK_VERIFICATION_REGEX } from '../../../../../shared/constants';
import { TemplateExtended } from '@distribute/shared/api-types/templates';
import { useCollaboration } from '../../../../../entities/collaboration';
import { CollaborativeCalendarForm } from './CollaborativeCalendarForm';

export const TITLE_MAX_LENGTH = 60;
const BUTTON_LABEL_MAX_LENGTH = 40;

export type ConversionPopup = CalendarForm & {
  imageUrl: string;
  title: string;
  actionType: ActionTypePopUp;
  isFormName: boolean;
  isFormPhone: boolean;
  isFormMessage: boolean;
  buttonLabelFormType: string;
  buttonLabelLinkType: string;
  buttonUrl: string;
  isTriggerTime: boolean;
  triggerTimeDelay: string;
  triggerTimeVisits: string;
  isTriggerExit: boolean;
};

type Props = {
  currentPage: Page | TemplateExtended;
};

export const CollaborativeEditorSidebarPopUpPanel: React.FC<Props> = ({
  currentPage,
}) => {
  const dispatch = useDispatch();
  const selectedPopupSection = useSelector(
    editorSidebarModel.selectors.selectSelectedPopupSection
  );

  const setSelectedPopupSection = (value: 'content' | 'triggers') => {
    dispatch(editorSidebarModel.actions.setSelectedPopupSection(value));
  };

  const handleClose = () => {
    dispatch(editorSidebarModel.actions.setActiveConversionSettingsPanel(null));
    dispatch(conversionKitModel.actions.setPopUpEdited(undefined));
    setSelectedPopupSection('content');
  };

  const { popUpData, updatePopupField, alertBarData, ctaData } =
    useCollaboration();

  const {
    actionType,
    imageUrl,
    description,
    title,
    isFormName,
    isFormPhone,
    isFormMessage,
    isTriggerTime,
    isTriggerExit,
    triggerTimeDelay,
    triggerTimeVisits,
    buttonLabelFormType,
    buttonLabelLinkType,
    buttonUrl,
    calendarProvider,
    calendarSchedulingLink,
    calendarCalendlyBgColor,
    calendarCalendlyButtonColor,
    calendarCalendlyTextColor,
    calendarIsShowCalendlyCookieBanner,
    calendarIsShowCalendlyPageDetails,
    isTitleEnabled,
    isDescriptionEnabled,
    isImageEnabled,
  } = popUpData;

  const { isActive: alertBarIsActive, actionType: alertBarActionType } =
    alertBarData;
  const { isActive: CTAIsActive, actionType: CTAActionType } = ctaData;

  const popUp = useSelector(conversionKitModel.selectors.selectPopUpWithError);
  const updatePopUpIsLoading = useSelector(
    conversionKitModel.selectors.selectUpdatePopUpIsLoading
  );

  const validationSchema = useMemo(
    () =>
      object().shape({
        title: string().when([], {
          is: () =>
            isTitleEnabled &&
            (actionType === ActionTypePopUp.FORM ||
              actionType === ActionTypePopUp.LINK ||
              actionType === ActionTypePopUp.CALENDLY),
          then: string().max(TITLE_MAX_LENGTH).required(),
          otherwise: string().nullable(),
        }),
        isFormName: boolean(),
        isFormPhone: boolean(),
        isFormMessage: boolean(),
        buttonLabelFormType: string().when([], {
          is: () => actionType === ActionTypePopUp.FORM,
          then: string().max(BUTTON_LABEL_MAX_LENGTH),
          otherwise: string().nullable(),
        }),
        buttonLabelLinkType: string().when([], {
          is: () => actionType === ActionTypePopUp.LINK,
          then: string().max(BUTTON_LABEL_MAX_LENGTH),
          otherwise: string().nullable(),
        }),
        buttonUrl: string().when([], {
          is: () => actionType === ActionTypePopUp.LINK,
          then: string().matches(LINK_VERIFICATION_REGEX).required(),
          otherwise: string().nullable(),
        }),
        isTriggerTime: boolean(),
        triggerTimeVisits: string().when([], {
          is: () =>
            actionType === ActionTypePopUp.FORM ||
            actionType === ActionTypePopUp.LINK,
          then: string().required(),
          otherwise: string().nullable(),
        }),
        triggerTimeDelay: string().when([], {
          is: () =>
            actionType === ActionTypePopUp.FORM ||
            actionType === ActionTypePopUp.LINK,
          then: string().required(),
          otherwise: string().nullable(),
        }),
        isTriggerExit: boolean(),
        calendarSchedulingLink: string().when(
          ['calendarProvider'],
          (calendarProvider) => {
            if (actionType !== ActionTypePopUp.CALENDLY) {
              return string().nullable();
            }

            if (calendarProvider === CalendarProvider.CHILI_PIPER) {
              return string()
                .url()
                .matches(/^https:\/\/.+\.chilipiper\.com\/book\/.*/)
                .required();
            }

            if (calendarProvider === CalendarProvider.CALENDLY) {
              return string()
                .url()
                .matches(/^https:\/\/calendly\.com\/.*/)
                .required();
            }

            return string().url().required();
          }
        ),
      }),
    [actionType, isTitleEnabled]
  );

  const isUploading = useSelector(
    conversionKitModel.selectors.selectIsUploadImageLoading
  );
  const uploadProgress = useSelector(
    conversionKitModel.selectors.selectUploadingPopUpImageProgress
  );
  const isRemoving = useSelector(
    conversionKitModel.selectors.selectIsRemovePopUpImageLoading
  );

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    clearErrors,
  } = useForm<ConversionPopup>({
    resolver: yupResolver(validationSchema),
    defaultValues: useMemo(
      () => ({
        title: title ?? undefined,
        isFormName,
        isFormPhone,
        isFormMessage,
        buttonLabelFormType,
        buttonLabelLinkType,
        buttonUrl,
        isTriggerTime,
        triggerTimeVisits,
        triggerTimeDelay,
        isTriggerExit,
        calendarProvider: calendarProvider ?? CalendarProvider.CALENDLY,
        calendarSchedulingLink: calendarSchedulingLink ?? '',
        calendarIsShowCalendlyPageDetails,
        calendarIsShowCalendlyCookieBanner,
        calendarCalendlyTextColor,
        calendarCalendlyButtonColor,
        calendarCalendlyBgColor,
      }),
      [
        buttonLabelFormType,
        buttonLabelLinkType,
        buttonUrl,
        calendarCalendlyBgColor,
        calendarCalendlyButtonColor,
        calendarCalendlyTextColor,
        calendarIsShowCalendlyCookieBanner,
        calendarIsShowCalendlyPageDetails,
        calendarProvider,
        calendarSchedulingLink,
        isFormMessage,
        isFormName,
        isFormPhone,
        isTriggerExit,
        isTriggerTime,
        title,
        triggerTimeDelay,
        triggerTimeVisits,
      ]
    ),
  });

  const {
    field: { onChange: onChangeFullName },
  } = useController({
    control,
    defaultValue: false,
    name: 'isFormName',
  });

  const handleChangeIsFormName = (value: boolean) => {
    onChangeFullName(value);
    updatePopupField('isFormName', value);
  };

  const {
    field: { onChange: onChangeIsFormPhone },
  } = useController({
    control,
    defaultValue: false,
    name: 'isFormPhone',
  });

  const handleChangeIsFormPhone = (value: boolean) => {
    onChangeIsFormPhone(value);
    updatePopupField('isFormPhone', value);
  };

  const {
    field: { onChange: onChangeIsFormMessage },
  } = useController({
    control,
    defaultValue: false,
    name: 'isFormMessage',
  });

  const handleChangeIsFormMessage = (value: boolean) => {
    onChangeIsFormMessage(value);
    updatePopupField('isFormMessage', value);
  };

  const {
    field: { onChange: onChangeIsTriggerTime },
  } = useController({
    control,
    defaultValue: false,
    name: 'isTriggerTime',
  });

  const handleChangeIsTriggerTime = (value: boolean) => {
    onChangeIsTriggerTime(value);
    updatePopupField('isTriggerTime', value);
  };

  const {
    field: { onChange: onChangeTriggerTimeDelay },
  } = useController({
    control,
    defaultValue: '0',
    name: 'triggerTimeDelay',
  });

  const handleChangeTriggerTimeDelay = (value: string) => {
    onChangeTriggerTimeDelay(value);
    updatePopupField('triggerTimeDelay', value);
  };

  const {
    field: { onChange: onChangeTriggerTimeVisits },
  } = useController({
    control,
    defaultValue: '0',
    name: 'triggerTimeVisits',
  });

  const handleChangeTriggerTimeVisits = (value: string) => {
    onChangeTriggerTimeVisits(value);
    updatePopupField('triggerTimeVisits', value);
  };

  const {
    field: { onChange: onChangeIsTriggerExit },
  } = useController({
    control,
    defaultValue: false,
    name: 'isTriggerExit',
  });

  const handleChangeIsTriggerExit = (value: boolean) => {
    onChangeIsTriggerExit(value);
    updatePopupField('isTriggerExit', value);
  };

  const {
    field: { onChange: changeButtonLabelFormType },
  } = useController({
    control,
    name: 'buttonLabelFormType',
  });

  const {
    field: { onChange: changeButtonLabelLinkType },
  } = useController({
    control,
    name: 'buttonLabelLinkType',
  });

  const handleButtonLabelChange = (value: string) => {
    changeButtonLabelFormType(value);
    changeButtonLabelLinkType(value);
    updatePopupField('buttonLabelFormType', value);
    updatePopupField('buttonLabelLinkType', value);
  };

  const {
    field: { onChange: onChangeTitle },
  } = useController({
    control,
    name: 'title',
  });

  const handleChangeTitle = (value: string) => {
    onChangeTitle(value);
    updatePopupField('title', value);
  };

  const {
    field: { onChange: onChangeButtonUrl },
  } = useController({
    control,
    name: 'buttonUrl',
  });

  const handleChangeButtonUrl = (value: string) => {
    onChangeButtonUrl(value);
    updatePopupField('buttonUrl', value);
  };

  useEffect(() => {
    dispatch(
      conversionKitModel.actions.setPopUpEdited({
        ...popUp,
        title: isTitleEnabled ? title : null,
        description: isDescriptionEnabled ? description : null,
        imageUrl: isImageEnabled ? imageUrl ?? undefined : undefined,
        actionType,
        isFormName,
        isFormPhone,
        isFormMessage,
        buttonLabelFormType,
        buttonLabelLinkType,
        buttonUrl,
        calendarProvider,
        calendarSchedulingLink,
        calendarIsShowCalendlyPageDetails,
        calendarIsShowCalendlyCookieBanner,
        calendarCalendlyTextColor,
        calendarCalendlyButtonColor,
        calendarCalendlyBgColor,
      })
    );
  }, [
    popUp,
    dispatch,
    isTitleEnabled,
    title,
    isDescriptionEnabled,
    description,
    isImageEnabled,
    imageUrl,
    actionType,
    isFormName,
    isFormPhone,
    isFormMessage,
    buttonLabelFormType,
    buttonLabelLinkType,
    buttonUrl,
    calendarProvider,
    calendarSchedulingLink,
    calendarIsShowCalendlyPageDetails,
    calendarIsShowCalendlyCookieBanner,
    calendarCalendlyTextColor,
    calendarCalendlyButtonColor,
    calendarCalendlyBgColor,
  ]);

  const onChangeTab = useCallback(
    (tab: string) => {
      clearErrors();
      updatePopupField('actionType', tab as ActionTypePopUp);
      updatePopupField(
        'isTitleEnabled',
        tab === ActionTypePopUp.CALENDLY ? !!title : true
      );
    },
    [clearErrors, title, updatePopupField]
  );

  const handleFormSubmit = () => {
    updatePopupField('isActive', true);
    updatePopupField('title', isTitleEnabled ? title : null);
    updatePopupField('description', isDescriptionEnabled ? description : null);
    updatePopupField('buttonLabelFormType', buttonLabelFormType || 'Submit');
    updatePopupField('buttonLabelLinkType', buttonLabelLinkType || 'Submit');
    updatePopupField(
      'calendarSchedulingLink',
      actionType === ActionTypePopUp.CALENDLY ? calendarSchedulingLink : null
    );
    handleClose();
  };

  const onUpdateImage = useCallback(
    (image: File) => {
      dispatch(
        conversionKitModel.actions.changePopUpImage({
          documentContentId: currentPage.content.id,
          image,
          cb: (url) => {
            updatePopupField('imageUrl', url);
          },
        })
      );
    },
    [currentPage, dispatch, updatePopupField]
  );

  const onRemoveImage = useCallback(() => {
    dispatch(
      conversionKitModel.actions.removePopUpImage({
        documentContentId: currentPage.content.id,
      })
    );
    updatePopupField('imageUrl', undefined);
  }, [currentPage, dispatch, updatePopupField]);

  return (
    <>
      <header className="h-36 bg-base-white pt-6 px-4 border-b border-gray-200">
        <div
          className="flex gap-2 text-gray-600 text-sm font-semibold cursor-pointer mb-4"
          onClick={handleClose}
        >
          <Icon glyph={IconMap.ArrowLeft} width={20} />
          <span>Back</span>
        </div>
        <h2 className="text-2xl font-display font-medium text-gray-900">
          Pop-Up
        </h2>
        <div className="flex gap-4 mt-5 text-sm font-semibold text-gray-500">
          <div
            className={cn('h-8 cursor-pointer px-1', {
              'border-b-2 border-primary-700 text-primary-700':
                selectedPopupSection === 'content',
            })}
            onClick={() => setSelectedPopupSection('content')}
          >
            Content
          </div>
          <div
            className={cn('h-8 cursor-pointer px-1', {
              'border-b-2 border-primary-700 text-primary-700':
                selectedPopupSection === 'triggers',
            })}
            onClick={() => setSelectedPopupSection('triggers')}
          >
            Triggers
          </div>
        </div>
      </header>
      <div className="overflow-y-auto h-[calc(100%-220px)]">
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="py-6 px-4">
            {selectedPopupSection === 'content' && (
              <>
                <p className="text-gray-800 font-semibold mb-4">Type</p>
                <TabsGroup
                  active={actionType}
                  setActive={onChangeTab}
                  options={POP_UP_TABS}
                  className="!w-full"
                  optionClassName="flex-1"
                />
                {actionType !== ActionTypePopUp.CALENDLY && (
                  <>
                    <hr className="border-gray-200 my-6" />
                    <p className="text-gray-800 font-semibold mb-4">
                      Components
                    </p>
                    <LabelToggle
                      icon={IconMap.Image01}
                      text="Image"
                      checked={isImageEnabled}
                      onChange={() =>
                        updatePopupField('isImageEnabled', !isImageEnabled)
                      }
                      className="mb-3"
                    />
                    {isImageEnabled && (
                      <ImageInput
                        imageUrl={imageUrl ?? undefined}
                        isUploading={isUploading}
                        uploadProgress={uploadProgress}
                        isRemoving={isRemoving}
                        onUpdateImage={onUpdateImage}
                        onRemoveImage={onRemoveImage}
                        isLabel={false}
                        fileInputStyles="h-31"
                        className="mb-6"
                      />
                    )}
                    <PopupTitleAndDescriptionFields
                      description={description ?? ''}
                      title={title ?? ''}
                      errors={errors}
                      isDescriptionEnabled={isDescriptionEnabled}
                      isTitleEnabled={isTitleEnabled}
                      isTitleToggleDisabled={false}
                      onChangeDescription={(content) =>
                        updatePopupField('description', content)
                      }
                      onChangeDescriptionEnabled={() =>
                        updatePopupField(
                          'isDescriptionEnabled',
                          !isDescriptionEnabled
                        )
                      }
                      onChangeTitleEnabled={() =>
                        updatePopupField('isTitleEnabled', !isTitleEnabled)
                      }
                      onChangeTitle={handleChangeTitle}
                    />
                    <hr className="border-gray-200 my-6" />
                  </>
                )}
                {actionType === ActionTypePopUp.FORM && (
                  <>
                    <div>
                      <p className="text-gray-800 font-semibold mb-4">
                        Form Fields
                      </p>
                      <LabelToggle
                        icon={IconMap.User03}
                        text="Name"
                        checked={isFormName}
                        onChange={handleChangeIsFormName}
                        className="mb-3"
                      />
                      <LabelToggle
                        icon={IconMap.EmailSymbol}
                        text="Email"
                        checked={true}
                        onChange={() => null}
                        disabled
                        className="mb-3"
                      />
                      <LabelToggle
                        icon={IconMap.Phone}
                        text="Phone"
                        checked={isFormPhone}
                        onChange={handleChangeIsFormPhone}
                        className="mb-3"
                      />
                      <LabelToggle
                        icon={IconMap.MessageDotsCircle}
                        text="Message"
                        checked={isFormMessage}
                        onChange={handleChangeIsFormMessage}
                      />
                      <hr className="border-gray-200 my-6" />
                    </div>
                    <p className="text-gray-800 font-semibold mb-4">Button</p>
                    <Label
                      icon={IconMap.TypeSquare}
                      text="Label"
                      className="mb-3"
                    />
                    <Input
                      maxCharacters={BUTTON_LABEL_MAX_LENGTH}
                      className="p-3"
                      type="text"
                      placeholder="Submit"
                      isError={!!errors.buttonLabelFormType}
                      value={buttonLabelFormType}
                      heightSize="md"
                      onChange={({ target: { value } }) =>
                        handleButtonLabelChange(value)
                      }
                    />
                  </>
                )}
                {actionType === ActionTypePopUp.LINK && (
                  <>
                    <Label
                      icon={IconMap.TypeSquare}
                      text="Label"
                      className="mb-3"
                    />
                    <Input
                      maxCharacters={BUTTON_LABEL_MAX_LENGTH}
                      className="p-3"
                      type="text"
                      placeholder="Submit"
                      isError={!!errors.buttonLabelLinkType}
                      value={buttonLabelLinkType}
                      onChange={({ target: { value } }) =>
                        handleButtonLabelChange(value)
                      }
                    />
                    <Label
                      icon={IconMap.Link}
                      text="URL"
                      className="mt-4 mb-3"
                      isRequired
                    />
                    <Input
                      className="p-3"
                      type="text"
                      placeholder="e.g. https://example.com/link"
                      isError={!!errors.buttonUrl}
                      value={buttonUrl}
                      onChange={({ target: { value } }) =>
                        handleChangeButtonUrl(value)
                      }
                    />
                  </>
                )}
                {actionType === ActionTypePopUp.CALENDLY && (
                  <CollaborativeCalendarForm
                    source="popup"
                    control={control}
                    register={register}
                    errors={errors}
                    calendarProvider={calendarProvider}
                    calendarCalendlyBgColor={calendarCalendlyBgColor}
                    calendarCalendlyButtonColor={calendarCalendlyButtonColor}
                    calendarCalendlyTextColor={calendarCalendlyTextColor}
                    calendarIsShowCalendlyCookieBanner={
                      calendarIsShowCalendlyCookieBanner
                    }
                    calendarIsShowCalendlyPageDetails={
                      calendarIsShowCalendlyPageDetails
                    }
                    calendarSchedulingLink={calendarSchedulingLink}
                    updateCalendarCalendlyBgColor={(value) =>
                      updatePopupField('calendarCalendlyBgColor', value)
                    }
                    updateCalendarCalendlyButtonColor={(value) =>
                      updatePopupField('calendarCalendlyButtonColor', value)
                    }
                    updateCalendarCalendlyTextColor={(value) =>
                      updatePopupField('calendarCalendlyTextColor', value)
                    }
                    updateCalendarIsShowCalendlyCookieBanner={(value) =>
                      updatePopupField(
                        'calendarIsShowCalendlyCookieBanner',
                        value
                      )
                    }
                    updateCalendarIsShowCalendlyPageDetails={(value) =>
                      updatePopupField(
                        'calendarIsShowCalendlyPageDetails',
                        value
                      )
                    }
                    updateCalendarProvider={(value) =>
                      updatePopupField('calendarProvider', value)
                    }
                    updateCalendarSchedulingLink={(value) =>
                      updatePopupField('calendarSchedulingLink', value)
                    }
                    popupTitleAndDescriptionFields={
                      <PopupTitleAndDescriptionFields
                        description={description ?? ''}
                        title={title ?? ''}
                        errors={errors}
                        isDescriptionEnabled={isDescriptionEnabled}
                        isTitleEnabled={isTitleEnabled}
                        isTitleToggleDisabled={
                          actionType !== ActionTypePopUp.CALENDLY
                        }
                        onChangeDescription={(content) =>
                          updatePopupField('description', content)
                        }
                        onChangeDescriptionEnabled={() =>
                          updatePopupField(
                            'isDescriptionEnabled',
                            !isDescriptionEnabled
                          )
                        }
                        onChangeTitleEnabled={() =>
                          updatePopupField('isTitleEnabled', !isTitleEnabled)
                        }
                        onChangeTitle={handleChangeTitle}
                      />
                    }
                  />
                )}
              </>
            )}
            {selectedPopupSection === 'triggers' && (
              <>
                {alertBarIsActive &&
                  alertBarActionType === ActionTypeAlertBar.POPUP && (
                    <BehaviorSettingsItem
                      checked
                      isDisabled
                      content={false}
                      icon={IconMap.CursorClick02}
                      title="Alert Bar CTA"
                      text="Upon clicking the Alert Bar CTA, this pop-up will be activated for the user."
                      setChecked={() => null}
                    />
                  )}
                {CTAIsActive && CTAActionType === ActionTypeCTA.POPUP && (
                  <BehaviorSettingsItem
                    checked
                    isDisabled
                    content={false}
                    icon={IconMap.CursorClick02}
                    title="CTA Section"
                    text="Upon clicking the CTA Section button, this pop-up will be activated for the user."
                    setChecked={() => null}
                  />
                )}
                <BehaviorSettingsItem
                  title="Time"
                  checked={isTriggerTime}
                  setChecked={handleChangeIsTriggerTime}
                  text="Set your pop-up to appear after a certain amount of time has elapsed or a certain number of page views have been reached."
                  content={true}
                  icon={IconMap.ClockStopwatch}
                >
                  <Input
                    labelInsideInput="seconds"
                    labelInsideInputRightSide
                    label="Delay before opening"
                    value={triggerTimeDelay}
                    onChange={({ target: { value } }) =>
                      handleChangeTriggerTimeDelay(value)
                    }
                    type="number"
                    messageText={
                      !errors.triggerTimeDelay
                        ? 'Set 0 to open immediately'
                        : 'This field can’t be empty'
                    }
                    min={0}
                    isError={!!errors.triggerTimeDelay}
                  />
                  <Input
                    labelInsideInput="visits"
                    labelInsideInputRightSide
                    label="Page visits before opening"
                    value={triggerTimeVisits}
                    onChange={({ target: { value } }) =>
                      handleChangeTriggerTimeVisits(value)
                    }
                    type="number"
                    messageText={
                      !errors.triggerTimeVisits
                        ? 'Set 0 to show on every visit'
                        : 'This field can’t be empty'
                    }
                    min={0}
                    isError={!!errors.triggerTimeVisits}
                  />
                </BehaviorSettingsItem>
                <BehaviorSettingsItem
                  title="Exit Intent"
                  checked={isTriggerExit}
                  setChecked={handleChangeIsTriggerExit}
                  text="Set an exit pop-up to display when visitors move their mouse in a way that indicates they are about to exit the page."
                  content={false}
                  icon={IconMap.LogOut02}
                />
              </>
            )}
          </div>
          <EditorSidebarConversionsPanelFooter
            isAddBtnLoading={updatePopUpIsLoading}
            addBtnTitle={buttonLabelFormType === null ? 'Add' : 'Save'}
            onClose={handleClose}
          ></EditorSidebarConversionsPanelFooter>
        </form>
      </div>
    </>
  );
};
