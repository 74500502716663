import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { EditorSidebarContent, EditorSidebarHeader } from './components';
import {
  ActiveConversionSettingsPanel,
  SidebarStepsEnum,
  editorSidebarModel,
} from '../../../features/editor-sidebar';
import { EditorSidebarAlertBarPanel } from './components/conversion-panels/EditorSidebarAlertBarPanel';
import { EditorSidebarPopUpPanel } from './components/conversion-panels/EditorSidebarPopUpPanel';
import { EditorSidebarCTAPanel } from './components/conversion-panels/EditorSidebarCTAPanel';
import { useWindowWidth } from '@distribute/frontend/utils';
import { TemplateExtended } from '@distribute/shared/api-types/templates';
import { Page } from '@distribute/shared/types';
import { EditorSidebarGatedContentPanel } from './components/conversion-panels/EditorSidebarGatedContentPanel';
import { EditorSidebarDistributeEditCoverPanel } from './components/distribute-tab';
import { useGleapBlockRepositionWithSidebar } from '../../../entities/gleap';
import { EditorSidebarSqueezePagePanel } from './components/conversion-panels/EditorSidebarSqueezePagePanel';
import { socialModel } from '../../../entities/social-auth';
import { teamsModel } from '../../../features/teams';
import { getFullPageUrl } from '../../../shared/lib';
import { customDomainsModel } from '../../../features/custom-domains';
import { useCollaboration } from '../../../entities/collaboration';
import { CollaborativeEditorSidebarAlertBarPanel } from './components/conversion-panels/CollaborativeEditorSidebarAlertBarPanel';
import { CollaborativeEditorSidebarPopUpPanel } from './components/conversion-panels/CollaborativeEditorSidebarPopUpPanel';
import { CollaborativeEditorSidebarCTAPanel } from './components/conversion-panels/CollaborativeEditorSidebarCTAPanel';
import { CollaborativeEditorSidebarGatedContentPanel } from './components/conversion-panels/CollaborativeEditorSidebarGatedContentPanel';
import { CollaborativeEditorSidebarSqueezePagePanel } from './components/conversion-panels/CollaborativeEditorSidebarSqueezePagePanel';
import { useUserAgentDevices } from '../../../shared/hooks/useUserAgentDevices';
import ContactCardEditor from './components/contactcard-editor-tab';

type Props = {
  currentPage: Page | TemplateExtended;
  isTemplateMode?: boolean;
};

export const EditorSidebar: React.FC<Props> = ({
  currentPage,
  isTemplateMode,
}) => {
  const isOpen = useSelector(editorSidebarModel.selectors.selectIsSidebarOpen);
  const pageSlug = (currentPage as Page).pageSlug;

  const activeConversionSettingsPanel = useSelector(
    editorSidebarModel.selectors.selectActiveConversionSettingsPanel
  );

  const isAlertBarPanelOpen =
    activeConversionSettingsPanel === ActiveConversionSettingsPanel.ALERT_BAR;
  const isPopUpPanelOpen =
    activeConversionSettingsPanel === ActiveConversionSettingsPanel.POP_UP;
  const isCTAPanelOpen =
    activeConversionSettingsPanel === ActiveConversionSettingsPanel.CTA;
  const isGatedContentPanelOpen =
    activeConversionSettingsPanel ===
    ActiveConversionSettingsPanel.GATED_CONTENT;
  const isSqueezePagePanelOpen =
    activeConversionSettingsPanel ===
    ActiveConversionSettingsPanel.SQUEEZE_PAGE;
  const isDistributeEditCoverPanelOpen = useSelector(
    editorSidebarModel.selectors.selectDistributeEditCoverPanelOpen
  );
  const isContactCardEditorOpen =
    activeConversionSettingsPanel ===
    ActiveConversionSettingsPanel.CONTACT_CARD_EDITOR;

  const dispatch = useDispatch();

  const { domain } = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );
  const customDomain = useSelector(
    customDomainsModel.selectors.selectVerifiedCustomDomain
  );

  const getPageUrl = () =>
    !isTemplateMode
      ? getFullPageUrl({
          slug: pageSlug,
          domain,
          customDomain,
        })
      : '';

  const [pageUrl, setPageUrl] = useState(() => getPageUrl());

  const postContent = useSelector(
    socialModel.selectors.selectSocialPostContent
  );

  const setPostContent = useCallback(
    (content: string) => {
      dispatch(socialModel.actions.setSocialPostContent(content));
    },
    [dispatch]
  );

  useEffect(() => {
    if (isTemplateMode) return;
    if (postContent === '') {
      setPostContent(
        `<p><br>To find out more, please follow the link provided:<br/><customlink href="${pageUrl}" text="${pageUrl}"></customlink></p>`
      );
    }
  }, [isTemplateMode, pageUrl, postContent, setPostContent]);

  useEffect(() => {
    if (postContent && pageSlug) {
      const newPageUrl = getPageUrl();
      setPostContent(postContent.replace(new RegExp(pageUrl, 'g'), newPageUrl));
      setPageUrl(newPageUrl);
    }
  }, [pageSlug]);

  useEffect(() => {
    return () => {
      dispatch(
        editorSidebarModel.actions.setActiveConversionSettingsPanel(null)
      );
      dispatch(editorSidebarModel.actions.setSidebarOpen(false));
      dispatch(
        editorSidebarModel.actions.setSelectedSidebarStep(
          SidebarStepsEnum.CONVERSIONS
        )
      );
      dispatch(editorSidebarModel.actions.setPersonalizedLinkTitle(null));
      dispatch(editorSidebarModel.actions.setPersonalizedLinkPersonName(''));
      dispatch(
        editorSidebarModel.actions.setDistributeEditCoverPanelOpen(false)
      );
      dispatch(socialModel.actions.setSocialPostContent(''));
    };
  }, [dispatch]);

  const { isMobile } = useWindowWidth();
  const { isTablet } = useUserAgentDevices();

  useGleapBlockRepositionWithSidebar({ isOpen });

  const { isCollaborationEnabled } = useCollaboration();

  const isSoloMode = isTemplateMode || !isCollaborationEnabled;

  const onTransitionEnd = useCallback(() => {
    dispatchEvent(new Event('resize'));
  }, []);

  if (isMobile || isTablet) return null;

  const showContent =
    !isAlertBarPanelOpen &&
    !isPopUpPanelOpen &&
    !isCTAPanelOpen &&
    !isGatedContentPanelOpen &&
    !isDistributeEditCoverPanelOpen &&
    !isSqueezePagePanelOpen &&
    !isContactCardEditorOpen;

  return (
    <aside
      id="editor-sidebar"
      className={cn(
        'w-120 bg-gray-75 fixed h-[calc(100vh-64px)] z-40 border-l border-gray-200 transition-all top-16',
        {
          'right-0': isOpen,
          '-right-120': !isOpen,
        }
      )}
      onTransitionEnd={onTransitionEnd}
    >
      {isAlertBarPanelOpen && isSoloMode && (
        <EditorSidebarAlertBarPanel
          currentPage={currentPage as Page | TemplateExtended}
        />
      )}
      {isAlertBarPanelOpen && !isSoloMode && (
        <CollaborativeEditorSidebarAlertBarPanel />
      )}
      {isPopUpPanelOpen && isSoloMode && (
        <EditorSidebarPopUpPanel
          currentPage={currentPage as TemplateExtended}
        />
      )}
      {isPopUpPanelOpen && !isSoloMode && (
        <CollaborativeEditorSidebarPopUpPanel currentPage={currentPage} />
      )}
      {isCTAPanelOpen && isSoloMode && (
        <EditorSidebarCTAPanel
          currentPage={currentPage as Page | TemplateExtended}
        />
      )}
      {isCTAPanelOpen && !isSoloMode && (
        <CollaborativeEditorSidebarCTAPanel currentPage={currentPage} />
      )}
      {isGatedContentPanelOpen && isSoloMode && (
        <EditorSidebarGatedContentPanel
          currentPage={currentPage as TemplateExtended}
        />
      )}
      {isGatedContentPanelOpen && !isSoloMode && (
        <CollaborativeEditorSidebarGatedContentPanel />
      )}

      {isDistributeEditCoverPanelOpen && (
        <EditorSidebarDistributeEditCoverPanel />
      )}
      {isSqueezePagePanelOpen && isSoloMode && (
        <EditorSidebarSqueezePagePanel
          currentPage={currentPage as TemplateExtended}
        />
      )}
      {isSqueezePagePanelOpen && !isSoloMode && (
        <CollaborativeEditorSidebarSqueezePagePanel currentPage={currentPage} />
      )}
      {isContactCardEditorOpen && <ContactCardEditor />}

      {showContent && (
        <>
          <EditorSidebarHeader isTemplateMode={isTemplateMode} />
          <EditorSidebarContent isTemplateMode={isTemplateMode} />
        </>
      )}
    </aside>
  );
};
