import { pagesModel } from '../../../features/pages';
import { pageSettingsModel } from '../../../features/page-settings';
import { conversionKitModel } from '../../../features/conversion-kit';

import { fork, takeEvery } from 'redux-saga/effects';
import { aiModel } from '../../../features/ai';
import { editorSidebarModel } from '../../../features/editor-sidebar';
import { socialModel } from '../../../entities/social-auth';
import { snippetsModel } from '../../../features/snippets';

export function* editorPageWorker() {
  yield fork(pageSettingsModel.sagas.watchUploadSeoConfigurationImage);
  yield fork(conversionKitModel.sagas.watchUploadCTAImage);
  yield fork(conversionKitModel.sagas.watchUploadPopUpImage);
  yield fork(aiModel.sagas.editorAiWorker);
  yield fork(aiModel.sagas.generatePageWorker);
  yield fork(snippetsModel.sagas.snippetsWorker);

  yield takeEvery(
    pagesModel.actions.changePageIcon,
    pagesModel.sagas.changePageIcon
  );
  yield takeEvery(
    pagesModel.actions.changeCoverImageUrl,
    pagesModel.sagas.changePageCoverImage
  );
  yield takeEvery(
    pagesModel.actions.changeBrandLogo,
    pagesModel.sagas.changePageBrandLogo
  );
  yield takeEvery(
    pagesModel.actions.deletePageBrandLogo,
    pagesModel.sagas.deletePageBrandLogo
  );
  yield takeEvery(
    pagesModel.actions.deletePageCoverImage,
    pagesModel.sagas.deletePageCoverImage
  );
  yield takeEvery(
    pagesModel.actions.changePageContent,
    pagesModel.sagas.changePageContent
  );
  yield takeEvery(
    pagesModel.actions.changeBrandColor,
    pagesModel.sagas.changeBrandColor
  );
  yield takeEvery(
    pagesModel.actions.changePageStyle,
    pagesModel.sagas.changePageStyle
  );
  yield takeEvery(pagesModel.actions.updatePage, pagesModel.sagas.updatePage);
  yield takeEvery(
    pagesModel.actions.updatePageFromTemplate,
    pagesModel.sagas.updatePageFromTemplate
  );
  yield takeEvery(
    pageSettingsModel.actions.updateSeoConfiguration,
    pageSettingsModel.sagas.updateSeoConfiguration
  );
  yield takeEvery(
    pageSettingsModel.actions.changeSeoConfigurationsImage,
    pageSettingsModel.sagas.changeSeoConfigurationsImage
  );
  yield takeEvery(
    pageSettingsModel.actions.removeSeoConfigurationsImage,
    pageSettingsModel.sagas.removeSeoConfigurationsImage
  );
  yield takeEvery(
    editorSidebarModel.actions.setActiveConversionSettingsPanel,
    editorSidebarModel.sagas.onActiveConversionPanelOpen
  );
  yield takeEvery(
    editorSidebarModel.actions.updatePersonalizedCover,
    editorSidebarModel.sagas.updatePersonalizedCover
  );
  yield takeEvery(
    editorSidebarModel.actions.generatePersonalizedLink,
    editorSidebarModel.sagas.generatePersonalizedLink
  );
  yield takeEvery(
    socialModel.actions.connectToLinkedin,
    socialModel.sagas.connectToLinkedin
  );
  yield takeEvery(
    socialModel.actions.shareLinkedinPost,
    socialModel.sagas.shareLinkedinPost
  );
  yield takeEvery(
    socialModel.actions.revokeLinkedinToken,
    socialModel.sagas.revokeLinkedinToken
  );
  yield takeEvery(
    socialModel.actions.fetchLinkedinProfileData,
    socialModel.sagas.fetchLinkedinProfileData
  );
  yield takeEvery(
    socialModel.actions.uploadPostCover,
    socialModel.sagas.uploadPostCover
  );
  yield takeEvery(
    socialModel.actions.connectToTwitter,
    socialModel.sagas.connectToTwitter
  );
  yield takeEvery(
    socialModel.actions.shareTwitterPost,
    socialModel.sagas.shareTwitterPost
  );
  yield takeEvery(
    socialModel.actions.revokeTwitterToken,
    socialModel.sagas.revokeTwitterToken
  );

  yield takeEvery(
    snippetsModel.actions.updateSnippetUsedCount,
    snippetsModel.sagas.updateSnippetUsedCount
  );

  yield takeEvery(
    pagesModel.actions.setContentNavigation,
    pagesModel.sagas.setContentNavigation
  );
}
