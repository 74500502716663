import { ConversionKitCalendarData } from './calendar';

export enum ActionTypeCTA {
  FORM = 'form',
  LINK = 'link',
  POPUP = 'popup',
  CALENDLY = 'calendly',
}

export type CTA = ConversionKitCalendarData & {
  id: string;
  isActive: boolean;
  imageUrl?: string;
  title?: string;
  description: string;
  actionType: ActionTypeCTA;
  isFormName: boolean;
  isFormPhone: boolean;
  isFormMessage: boolean;
  buttonLabelFormType?: string;
  buttonLabelLinkType?: string;
  buttonLabelPopupType?: string;
  buttonUrl?: string;
};

export type CollaborativeCTA = CTA & {
  isDescriptionEnabled: boolean;
  isImageEnabled: boolean;
};
