import React, { useMemo, useState } from 'react';
import { Dropdown, Icon, Tooltip } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useDispatch, useSelector } from 'react-redux';
import { templatesModel } from '../../../../features/templates';
import cn from 'classnames';
import {
  TemplateSidebarTabsEnum,
  editorSidebarModel,
} from '../../../../features/editor-sidebar';
import { DeleteTemplateConfirmationModal } from '../../../../features/templates/ui/components/DeleteTemplateConfirmationModal';

type Props = {
  listStyles?: string;
  align?: 'start' | 'end' | 'center';
};

export const TemplateSettingsDropdown: React.FC<Props> = ({
  listStyles,
  align = 'start',
}) => {
  const currentTemplate = useSelector(
    templatesModel.selectors.selectCurrentTemplate
  );

  const dispatch = useDispatch();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const templateMenuItems = useMemo(
    () => [
      {
        id: 'Settings',
        label: 'Settings',
        onClick: () => {
          dispatch(editorSidebarModel.actions.setSidebarOpen(true));
          dispatch(
            editorSidebarModel.actions.setSelectedTemplateSidebarTab(
              TemplateSidebarTabsEnum.SETTINGS
            )
          );
        },
        iconName: IconMap.Settings,
        iconWidth: 16,
      },
      {
        id: 'Delete',
        label: 'Delete template',
        onClick: () => setIsDeleteModalOpen(true),
        iconName: IconMap.Delete,
        isSeparatedFromTop: true,
      },
    ],
    [dispatch]
  );

  return (
    <>
      <Dropdown
        listStyles={cn('shadow-lg', listStyles)}
        triggerComponent={
          <Tooltip
            trigger={
              <DropdownMenu.Trigger className="rounded-lg flex items-center justify-center focus:outline-none text-gray-600 border border-gray-300 hover:bg-gray-50 h-9 w-9">
                <span className="flex items-center justify-center">
                  <Icon glyph={IconMap.DotsVertical} className="w-5 h-5" />
                </span>
              </DropdownMenu.Trigger>
            }
            sideOffset={4}
          >
            <p className="px-2 py-1 font-semibold text-xs">More actions</p>
          </Tooltip>
        }
        items={templateMenuItems}
        align={align}
        isModalMode={false}
      />
      {currentTemplate && (
        <DeleteTemplateConfirmationModal
          isOpen={isDeleteModalOpen}
          returnToWorkspace
          onClose={() => {
            setIsDeleteModalOpen(false);
          }}
          template={currentTemplate}
        />
      )}
    </>
  );
};
