import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { object, string } from 'yup';

import { Button, Input, Icon, LinkAsButton } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';
import { PopUpConfiguration } from './PopUpConfiguration';
import { ActionTypePopUp, PopUp as PopupType } from '@distribute/shared/types';
import { subscribePopupModel } from '../../model';
import { PagePermissions } from '../../../pages/lib/getPagePermissions';
import { editorSidebarModel } from '../../../editor-sidebar';
import { PopUpCalendar } from './PopUpCalendar';
import { parseHtml } from '../../../../shared/lib/parseHtml';

type SubscribePopupForm = {
  name: string;
  email: string;
  phone: string;
  message: string;
};

type Props = {
  popUp: PopupType;
  pagePermissions?: PagePermissions;
};

export const PopUp: React.FC<Props> = ({ popUp, pagePermissions }) => {
  const [popupEditedData, setPopupEditedData] = useState<Partial<PopupType>>();
  const dispatch = useDispatch();
  const [isSubscribeBarOpen, setIsSubscribeBarOpen] = useState<boolean>(false);
  const isSubscribeModalOpen = useSelector(
    subscribePopupModel.selectors.selectSubscribePopupIsModalOpen
  );
  const isAnyConversionPanelOpen = useSelector(
    editorSidebarModel.selectors.selectIsAnyConversionPanelOpen
  );

  const popupData = popupEditedData ?? popUp;

  const validationSchema = useMemo(
    () =>
      object().shape({
        name: string().when([], {
          is: () => popUp.isFormName,
          then: string(),
          otherwise: string().nullable(),
        }),
        email: string().email().required(),
        phone: string().when([], {
          is: () => popUp.isFormPhone,
          then: string(),
          otherwise: string().nullable(),
        }),
        message: string().when([], {
          is: () => popUp.isFormMessage,
          then: string(),
          otherwise: string().nullable(),
        }),
      }),
    [popUp]
  );

  const {
    register,
    formState: { errors },
  } = useForm<SubscribePopupForm>({
    resolver: yupResolver(validationSchema),
  });

  const setIsSubscribeModalOpen = useCallback(() => {
    dispatch(subscribePopupModel.actions.setIsModalOpen(false));
  }, [dispatch]);

  const handleCloseEditPopup = useCallback(() => {
    setIsSubscribeBarOpen(false);
    setPopupEditedData(undefined);
  }, []);

  const handleSubscribeModalClose = useCallback(() => {
    setIsSubscribeModalOpen();
    handleCloseEditPopup();
  }, [setIsSubscribeModalOpen, handleCloseEditPopup]);

  const isSidebarOpen = useSelector(
    editorSidebarModel.selectors.selectIsSidebarOpen
  );
  const isActionTypeCalendar = popUp.actionType === ActionTypePopUp.CALENDLY;

  if (!isSubscribeModalOpen) {
    return null;
  }

  return (
    <div className="transition-all">
      <div
        className={classNames(
          'fixed inset-0 flex items-center z-40 justify-center px-4 pb-4 pt-20 bg-bg-200 transition-all',
          {
            'w-full': !isSidebarOpen,
            'w-[calc(100%-480px)]': isSidebarOpen,
          }
        )}
      >
        <div
          className={classNames(
            'group shadow-white hover:shadow-primary-600 bg-base-white w-140 rounded-lg transition ease-in relative',
            {
              'shadow-primary-600': isSubscribeBarOpen,
              '!w-160': isActionTypeCalendar,
            }
          )}
        >
          {pagePermissions?.isCanEditConversionTools &&
            !isAnyConversionPanelOpen && (
              <PopUpConfiguration isSubscribeBarOpen={isSubscribeBarOpen} />
            )}
          <div className="relative rounded-lg">
            <div
              className={classNames('absolute top-4 right-4', {
                '!-right-11 !top-0':
                  isActionTypeCalendar &&
                  (!popupData.calendarSchedulingLink ||
                    (popupData.title === null &&
                      popupData.description === null)),
              })}
            >
              <Button
                onClick={handleSubscribeModalClose}
                size="sm"
                color="secondary"
                variant="icon"
              >
                <Icon glyph={IconMap.CrossClose} className="text-gray-500" />
              </Button>
            </div>
            {isActionTypeCalendar && <PopUpCalendar popupData={popUp} />}
            {!isActionTypeCalendar && (
              <>
                {popUp.imageUrl && (
                  <div className="h-40">
                    <img
                      className="h-full w-full rounded-tl-lg rounded-tr-lg object-cover"
                      src={popUp.imageUrl}
                      alt="subscribe"
                    />
                  </div>
                )}
                <div className="p-10 flex flex-col gap-6">
                  <div className="flex flex-col gap-2">
                    <p className="text-display-sm font-bold font-heading">
                      {popupData.title || 'Title'}
                    </p>
                    {popupData.description && (
                      <div className="text-gray-600 text-base font-paragraph">
                        {parseHtml(popupData.description)}
                      </div>
                    )}
                  </div>
                  {popupData.actionType === ActionTypePopUp.FORM && (
                    <form className="flex flex-col gap-3">
                      {popupData.isFormName && (
                        <Input
                          {...register('name')}
                          placeholder="Name"
                          type="text"
                          isError={!!errors.name}
                          isBrandStyles
                        />
                      )}
                      <Input
                        {...register('email')}
                        placeholder="Email"
                        type="text"
                        isError={!!errors.email}
                        isBrandStyles
                      />
                      {popupData.isFormPhone && (
                        <Input
                          {...register('phone')}
                          placeholder="Phone"
                          type="text"
                          isError={!!errors.phone}
                          isBrandStyles
                        />
                      )}
                      {popupData.isFormMessage && (
                        <Input
                          {...register('message')}
                          placeholder="Message"
                          type="text"
                          isError={!!errors.message}
                          isBrandStyles
                        />
                      )}
                      <Button
                        type="submit"
                        color="brand"
                        variant="text"
                        fontBold
                        fullWidth
                      >
                        {popupData.buttonLabelFormType || 'Submit'}
                      </Button>
                    </form>
                  )}

                  {popupData.actionType === ActionTypePopUp.LINK && (
                    <LinkAsButton
                      color="brand"
                      fontBold
                      href={popupData.buttonUrl}
                      target="_blank"
                    >
                      {popupData.buttonLabelLinkType || 'Submit'}
                    </LinkAsButton>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
