import React, { FC, useCallback, useMemo } from 'react';
import { object, string } from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { Input, Button, Icon, LinkAsButton } from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';
import { pagesModel } from '../../pages';
import { ActionTypeCTA } from '@distribute/shared/types';
import { subscribePopupModel } from '../../subscribe-popup';
import { PagePermissions } from '../../pages/lib/getPagePermissions';
import {
  ActiveConversionSettingsPanel,
  editorSidebarModel,
} from '../../editor-sidebar';
import { parseHtml } from '../../../shared/lib/parseHtml';
import { CollaborativeActionBannerCalendar } from './CollaborativeActionBannerCalendar';
import { useCollaboration } from '../../../entities/collaboration';

type ActionBannerForm = {
  name: string;
  email: string;
  phone: string;
  message: string;
};

type Props = {
  pagePermissions?: PagePermissions;
};

export const CollaborativeActionBanner: FC<Props> = ({ pagePermissions }) => {
  const dispatch = useDispatch();
  const isAnyConversionPanelOpen = useSelector(
    editorSidebarModel.selectors.selectIsAnyConversionPanelOpen
  );

  const { popUpData, ctaData } = useCollaboration();

  const {
    isFormName,
    isFormPhone,
    isFormMessage,
    actionType,
    isActive,
    title,
    description,
    buttonLabelFormType,
    buttonLabelLinkType,
    buttonLabelPopupType,
    buttonUrl,
    imageUrl,
  } = ctaData;

  const { isActive: popUpIsActive } = popUpData;

  const validationSchema = useMemo(
    () =>
      object().shape({
        name: string().when([], {
          is: () => isFormName,
          then: string(),
          otherwise: string().nullable(),
        }),
        email: string().email().required(),
        phone: string().when([], {
          is: () => isFormPhone,
          then: string(),
          otherwise: string().nullable(),
        }),
        message: string().when([], {
          is: () => isFormMessage,
          then: string(),
          otherwise: string().nullable(),
        }),
      }),
    [isFormMessage, isFormName, isFormPhone]
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ActionBannerForm>({
    resolver: yupResolver(validationSchema),
  });

  const isEditorPreview = useSelector(
    pagesModel.selectors.selectIsEditorPreview
  );

  const setIsSubscribePopupOpen = useCallback(() => {
    dispatch(subscribePopupModel.actions.setIsModalOpen(true));
  }, [dispatch]);

  const handleFormSubmit = (data: ActionBannerForm) => {
    return data;
  };

  const handleOpenCTASettings = () => {
    dispatch(editorSidebarModel.actions.setSidebarOpen(true));
    dispatch(
      editorSidebarModel.actions.setActiveConversionSettingsPanel(
        ActiveConversionSettingsPanel.CTA
      )
    );
  };

  const activeConversionSettingsPanel = useSelector(
    editorSidebarModel.selectors.selectActiveConversionSettingsPanel
  );

  const isCTAPanelOpen =
    activeConversionSettingsPanel === ActiveConversionSettingsPanel.CTA;
  const isCalendarActionType = actionType === ActionTypeCTA.CALENDLY;
  if (!isActive && !isCTAPanelOpen) {
    return null;
  }

  return (
    <div className="flex w-full pb-10.5 md:px-8 sm:px-4 max-w-212 mx-auto">
      <div
        className={classNames(
          `flex justify-center w-full bg-brand-900 rounded-lg group relative shadow-white hover:shadow-primary-600
              lg:mx-3 md:mx-0
              sm:h-fit-content sm:flex-col-reverse`,
          {
            'group-hover:flex hover:shadow-primary-600': !isEditorPreview,
            '!bg-transparent': actionType === ActionTypeCTA.CALENDLY,
          }
        )}
      >
        {isCalendarActionType ? (
          <CollaborativeActionBannerCalendar />
        ) : (
          <div className="shrink-0 max-w-120 w-full sm:max-w-full p-10 sm:p-6 flex flex-col gap-6">
            <div className="text-brand-text flex flex-col gap-2">
              <h3
                className={classNames(
                  'text-display-sm font-bold font-heading',
                  {
                    'opacity-50': !title,
                  }
                )}
              >
                {title || 'Title'}
              </h3>
              <div
                className={classNames('text-base font-paragraph', {
                  'opacity-50': !description,
                })}
              >
                {description ? parseHtml(description) : 'Description'}
              </div>
            </div>

            {actionType === ActionTypeCTA.FORM && (
              <form
                onSubmit={handleSubmit(handleFormSubmit)}
                className="flex flex-col gap-2"
              >
                {isFormName && (
                  <Input
                    {...register('name')}
                    placeholder="Name"
                    type="text"
                    isError={!!errors.name}
                    isBrandStyles
                  />
                )}
                <Input
                  {...register('email')}
                  placeholder="Email"
                  type="text"
                  isError={!!errors.email}
                  isBrandStyles
                />
                {isFormPhone && (
                  <Input
                    {...register('phone')}
                    placeholder="Phone"
                    type="text"
                    isError={!!errors.phone}
                    isBrandStyles
                  />
                )}
                {isFormMessage && (
                  <Input
                    {...register('message')}
                    placeholder="Message"
                    type="text"
                    isError={!!errors.message}
                    isBrandStyles
                  />
                )}
                <Button
                  fullWidth
                  type="submit"
                  color="brand"
                  variant="text"
                  fontBold
                >
                  {buttonLabelFormType || 'Subscribe'}
                </Button>
              </form>
            )}

            {actionType === ActionTypeCTA.LINK && (
              <LinkAsButton
                color="brand"
                fontBold
                href={buttonUrl}
                target="_blank"
              >
                {buttonLabelLinkType || 'Subscribe'}
              </LinkAsButton>
            )}

            {actionType === ActionTypeCTA.POPUP && (
              <Button
                color="brand"
                variant="text"
                fontBold
                onClick={popUpIsActive ? setIsSubscribePopupOpen : undefined}
              >
                {buttonLabelPopupType || 'Subscribe'}
              </Button>
            )}
          </div>
        )}
        {pagePermissions?.isCanEditConversionTools &&
          !isAnyConversionPanelOpen && (
            <button
              onClick={handleOpenCTASettings}
              className={classNames(
                'bg-primary-600 pl-0.5 text-base-white w-9 h-9 items-center justify-center rounded-full border-none hover:bg-primary-700 cursor-pointer absolute -top-4 right-28 hidden',
                {
                  'group-hover:flex': !isEditorPreview,
                }
              )}
            >
              <Icon glyph={IconMap.Edit2Icon} width={20} />
            </button>
          )}
        {imageUrl && !isCalendarActionType && (
          <div>
            <img
              className="h-full sm:max-h-42 w-full rounded-tr-lg rounded-br-lg sm:rounded-tl-lg sm:rounded-br-none object-cover"
              src={imageUrl}
              alt="CTA background"
            />
          </div>
        )}
      </div>
    </div>
  );
};
