import { call, put, select } from 'redux-saga/effects';
import { TeamFiltered } from '@distribute/shared/types';
import { createNotification, snackbarModel } from '../../../snackbar';
import { teamsModel } from '..';
import { loadTeamRelatedData } from './loadTeamRelatedData';
import { FirebaseUser } from '../../../../shared/types';
import { getUserFromFb } from '../../../../entities/auth-user';
import { logger } from '../../../../shared/lib';
import { redirect } from '../../../../entities/history';

export function* changeCurrentTeam(
  teamId: number,
  isRedirectToWorkspace = true
) {
  try {
    yield put(teamsModel.actions.setTeamsStatus('pending'));
    const teams: TeamFiltered[] = yield select(
      teamsModel.selectors.selectTeams
    );
    const currentTeam = teams.find((team) => team.id === teamId);

    yield put(teamsModel.actions.setCurrentTeam(currentTeam));
    yield call(loadTeamRelatedData);

    if (isRedirectToWorkspace) {
      yield call(redirect.toWorkspaceDefaultFolder);
    }

    const user: FirebaseUser = yield getUserFromFb();
    const authToken: string = yield user.getIdToken();

    yield put(teamsModel.actions.setTeamsStatus('success'));
  } catch (error: unknown) {
    logger.error(error);
    yield put(teamsModel.actions.setTeamsStatus('error'));
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to change the current team')
      )
    );
  }
}

export function* changeCurrentTeamWorker({
  payload: { teamId, isRedirectToWorkspace = true },
}: ReturnType<typeof teamsModel.actions.changeCurrentTeam>) {
  yield call(changeCurrentTeam, teamId, isRedirectToWorkspace);
}
