import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editorSidebarModel } from '../../../../../features/editor-sidebar';
import { conversionKitModel } from '../../../../../features/conversion-kit';
import {
  ActionTypeCTA,
  CalendarProvider,
  Page,
} from '@distribute/shared/types';
import { boolean, object, string } from 'yup';
import { useController, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { EditorSidebarConversionsPanelFooter } from './EditorSidebarConversionsPanelFooter';
import { LabelToggle } from '../../../../../shared/ui/LabelToggle';
import { IconMap } from '../../../../../shared/sprite';
import { Dropdown, ImageInput, Input } from '../../../../../shared/ui';
import { Label } from './Label';
import { CTA_TABS } from '../../../lib';
import { Icon } from '../../../../../shared/ui';
import cn from 'classnames';
import { RichTextEditor } from '../../../../../entities/tiptap-editor';
import { CalendarForm } from './CalendarForm';
import { LINK_VERIFICATION_REGEX } from '../../../../../shared/constants';
import { TemplateExtended } from '@distribute/shared/api-types/templates';
import { useCollaboration } from '../../../../../entities/collaboration';
import { CollaborativeCalendarForm } from './CollaborativeCalendarForm';

const TITLE_MAX_LENGTH = 60;
const DESCRIPTION_MAX_LENGTH = 200;
const BUTTON_LABEL_MAX_LENGTH = 40;

export type ActionBannerType = CalendarForm & {
  title: string;
  description: string;
  actionType: ActionTypeCTA;
  isFormName: boolean;
  isFormPhone: boolean;
  isFormMessage: boolean;
  buttonLabelFormType: string;
  buttonLabelLinkType: string;
  buttonLabelPopupType: string;
  buttonUrl: string;
};

type Props = {
  currentPage: Page | TemplateExtended;
};

export const CollaborativeEditorSidebarCTAPanel: React.FC<Props> = ({
  currentPage,
}) => {
  const dispatch = useDispatch();

  const { ctaData, updateCTAField, updatePopupField } = useCollaboration();

  const {
    isActive,
    imageUrl,
    title,
    description,
    actionType,
    isFormName,
    isFormPhone,
    isFormMessage,
    buttonLabelFormType,
    buttonLabelLinkType,
    buttonLabelPopupType,
    buttonUrl,
    calendarProvider,
    calendarSchedulingLink,
    calendarIsShowCalendlyPageDetails,
    calendarIsShowCalendlyCookieBanner,
    calendarCalendlyTextColor,
    calendarCalendlyButtonColor,
    calendarCalendlyBgColor,
    isDescriptionEnabled,
    isImageEnabled,
  } = ctaData;

  const handleClose = () => {
    dispatch(editorSidebarModel.actions.setActiveConversionSettingsPanel(null));
    dispatch(conversionKitModel.actions.setCTAEdited(undefined));
  };

  const cta = useSelector(conversionKitModel.selectors.selectCTAWithError);
  const updateCTAIsLoading = useSelector(
    conversionKitModel.selectors.selectUpdateCTAIsLoading
  );

  const validationSchema = useMemo(() => {
    return object().shape({
      title: string().when([], {
        is: () => actionType !== ActionTypeCTA.CALENDLY,
        then: string().max(TITLE_MAX_LENGTH).required(),
        otherwise: string().nullable(),
      }),
      isFormName: boolean(),
      isFormPhone: boolean(),
      isFormMessage: boolean(),
      buttonLabelFormType: string().when([], {
        is: () => actionType === ActionTypeCTA.FORM,
        then: string().max(BUTTON_LABEL_MAX_LENGTH),
        otherwise: string().nullable(),
      }),
      buttonLabelLinkType: string().when([], {
        is: () => actionType === ActionTypeCTA.LINK,
        then: string().max(BUTTON_LABEL_MAX_LENGTH),
        otherwise: string().nullable(),
      }),
      buttonLabelPopupType: string().when([], {
        is: () => actionType === ActionTypeCTA.POPUP,
        then: string().max(BUTTON_LABEL_MAX_LENGTH),
        otherwise: string().nullable(),
      }),
      buttonUrl: string().when([], {
        is: () => actionType === ActionTypeCTA.LINK,
        then: string().matches(LINK_VERIFICATION_REGEX).required(),
        otherwise: string().nullable(),
      }),
      calendarSchedulingLink: string().when(
        ['calendarProvider'],
        (calendarProvider) => {
          if (actionType !== ActionTypeCTA.CALENDLY) {
            return string().nullable();
          }

          if (calendarProvider === CalendarProvider.CHILI_PIPER) {
            return string()
              .url()
              .matches(/^https:\/\/.+\.chilipiper\.com\/book\/.*/)
              .required();
          }

          if (calendarProvider === CalendarProvider.CALENDLY) {
            return string()
              .url()
              .matches(/^https:\/\/calendly\.com\/.*/)
              .required();
          }

          return string().url().required();
        }
      ),
    });
  }, [actionType]);

  const isUploading = useSelector(
    conversionKitModel.selectors.selectIsUploadImageLoading
  );
  const uploadProgress = useSelector(
    conversionKitModel.selectors.selectUploadingCTAImageProgress
  );
  const isRemoving = useSelector(
    conversionKitModel.selectors.selectIsRemoveCTAImageLoading
  );

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    clearErrors,
  } = useForm<ActionBannerType>({
    resolver: yupResolver(validationSchema),
    defaultValues: useMemo(
      () => ({
        title,
        isFormName,
        isFormPhone,
        isFormMessage,
        buttonLabelFormType,
        buttonLabelLinkType,
        buttonLabelPopupType,
        buttonUrl: buttonUrl,
        calendarSchedulingLink: calendarSchedulingLink ?? '',
        calendarProvider: calendarProvider ?? CalendarProvider.CALENDLY,
        calendarIsShowCalendlyPageDetails,
        calendarIsShowCalendlyCookieBanner,
        calendarCalendlyTextColor,
        calendarCalendlyBgColor,
        calendarCalendlyButtonColor,
      }),
      [
        title,
        isFormName,
        isFormPhone,
        isFormMessage,
        buttonLabelFormType,
        buttonLabelLinkType,
        buttonLabelPopupType,
        buttonUrl,
        calendarSchedulingLink,
        calendarProvider,
        calendarIsShowCalendlyPageDetails,
        calendarIsShowCalendlyCookieBanner,
        calendarCalendlyTextColor,
        calendarCalendlyBgColor,
        calendarCalendlyButtonColor,
      ]
    ),
  });

  const {
    field: { onChange: onChangeTitle },
  } = useController({
    control,
    name: 'title',
  });

  const handleChangeTitle = (value: string) => {
    onChangeTitle(value);
    updateCTAField('title', value);
  };

  const {
    field: { onChange: onChangeDescription },
  } = useController({
    control,
    name: 'description',
  });

  const handleChangeDescription = (value: string) => {
    onChangeDescription(value);
    updateCTAField('description', value);
  };

  const {
    field: { onChange: onChangeIsFormName },
  } = useController({
    control,
    defaultValue: false,
    name: 'isFormName',
  });

  const handleChangeIsFormName = (value: boolean) => {
    onChangeIsFormName(value);
    updateCTAField('isFormName', value);
  };

  const {
    field: { onChange: onChangeIsFormPhone },
  } = useController({
    control,
    defaultValue: false,
    name: 'isFormPhone',
  });

  const handleChangeIsFormPhone = (value: boolean) => {
    onChangeIsFormPhone(value);
    updateCTAField('isFormPhone', value);
  };

  const {
    field: { onChange: onChangeIsFormMessage },
  } = useController({
    control,
    defaultValue: false,
    name: 'isFormMessage',
  });

  const handleChangeIsFormMessage = (value: boolean) => {
    onChangeIsFormMessage(value);
    updateCTAField('isFormMessage', value);
  };

  const {
    field: { onChange: changeButtonLabelFormType },
  } = useController({
    control,
    name: 'buttonLabelFormType',
  });

  const {
    field: { onChange: changeButtonLabelLinkType },
  } = useController({
    control,
    name: 'buttonLabelLinkType',
  });

  const {
    field: { onChange: changeButtonLabelPopupType },
  } = useController({
    control,
    name: 'buttonLabelPopupType',
  });

  const handleButtonLabelChange = (value: string) => {
    changeButtonLabelFormType(value);
    changeButtonLabelLinkType(value);
    changeButtonLabelPopupType(value);
    updateCTAField('buttonLabelFormType', value);
    updateCTAField('buttonLabelLinkType', value);
    updateCTAField('buttonLabelPopupType', value);
  };

  const {
    field: { onChange: changeButtonUrl },
  } = useController({
    control,
    name: 'buttonUrl',
  });

  const handleChangeCTAButtonUrl = (value: string) => {
    changeButtonUrl(value);
    updateCTAField('buttonUrl', value);
  };

  useEffect(() => {
    dispatch(
      conversionKitModel.actions.setCTAEdited({
        ...cta,
        title,
        description: isDescriptionEnabled ? description ?? '' : '',
        imageUrl: isImageEnabled ? imageUrl ?? undefined : undefined,
        actionType,
        buttonLabelFormType,
        buttonLabelLinkType,
        buttonLabelPopupType,
        buttonUrl,
        calendarProvider,
        calendarSchedulingLink,
        calendarIsShowCalendlyPageDetails,
        calendarIsShowCalendlyCookieBanner,
        calendarCalendlyTextColor,
        calendarCalendlyButtonColor,
        calendarCalendlyBgColor,
        isFormName,
        isFormPhone,
        isFormMessage,
      })
    );
  }, [
    cta,
    dispatch,
    title,
    isDescriptionEnabled,
    isImageEnabled,
    imageUrl,
    actionType,
    buttonLabelFormType,
    buttonLabelLinkType,
    buttonLabelPopupType,
    buttonUrl,
    calendarProvider,
    calendarSchedulingLink,
    calendarIsShowCalendlyPageDetails,
    calendarIsShowCalendlyCookieBanner,
    calendarCalendlyTextColor,
    calendarCalendlyButtonColor,
    calendarCalendlyBgColor,
    isFormName,
    isFormPhone,
    isFormMessage,
    description,
  ]);

  const onChangeTab = (tab: string) => {
    clearErrors();
    updateCTAField('actionType', tab as ActionTypeCTA);
  };

  const handleFormSubmit = () => {
    updateCTAField('isActive', true);
    updateCTAField('description', isDescriptionEnabled ? description : '');
    updateCTAField('buttonLabelFormType', buttonLabelFormType || 'Submit');
    updateCTAField('buttonLabelLinkType', buttonLabelLinkType || 'Submit');
    updateCTAField('buttonLabelPopupType', buttonLabelPopupType || 'Submit');
    updateCTAField(
      'calendarSchedulingLink',
      actionType === ActionTypeCTA.CALENDLY ? calendarSchedulingLink : null
    );

    if (isActive && actionType === ActionTypeCTA.POPUP) {
      updatePopupField('isActive', true);
    }

    handleClose();
  };

  const onUpdateImage = (image: File) => {
    dispatch(
      conversionKitModel.actions.changeCTAImage({
        documentContentId: currentPage.content.id,
        image,
        cb: (url) => {
          updateCTAField('imageUrl', url);
        },
      })
    );
  };

  const onRemoveImage = () => {
    dispatch(
      conversionKitModel.actions.removeCTAImage({
        documentContentId: currentPage.content.id,
      })
    );
    updateCTAField('imageUrl', undefined);
  };

  return (
    <>
      <header className="h-29 bg-base-white py-6 px-4 border-b border-gray-200">
        <div
          className="flex gap-2 text-gray-600 text-sm font-semibold cursor-pointer mb-4"
          onClick={handleClose}
        >
          <Icon glyph={IconMap.ArrowLeft} width={20} />
          <span>Back</span>
        </div>
        <h2 className="text-2xl font-display font-medium text-gray-900">
          CTA Section
        </h2>
      </header>
      <div className={cn('overflow-y-auto h-[calc(100%-192px)]')}>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="py-6 px-4">
            <p className="text-gray-800 font-semibold mb-4">Type</p>

            <Dropdown
              items={CTA_TABS}
              isModalMode={false}
              align="start"
              listStyles="!w-[var(--radix-dropdown-menu-trigger-width)]"
              triggerStyles="bg-base-white"
              currentItemId={actionType}
              onItemChange={onChangeTab}
            />

            {actionType === ActionTypeCTA.CALENDLY && (
              <CollaborativeCalendarForm
                source="cta"
                control={control}
                register={register}
                errors={errors}
                calendarProvider={calendarProvider}
                calendarCalendlyBgColor={calendarCalendlyBgColor}
                calendarCalendlyButtonColor={calendarCalendlyButtonColor}
                calendarCalendlyTextColor={calendarCalendlyTextColor}
                calendarIsShowCalendlyCookieBanner={
                  calendarIsShowCalendlyCookieBanner
                }
                calendarIsShowCalendlyPageDetails={
                  calendarIsShowCalendlyPageDetails
                }
                calendarSchedulingLink={calendarSchedulingLink}
                updateCalendarCalendlyBgColor={(value) =>
                  updateCTAField('calendarCalendlyBgColor', value)
                }
                updateCalendarCalendlyButtonColor={(value) =>
                  updateCTAField('calendarCalendlyButtonColor', value)
                }
                updateCalendarCalendlyTextColor={(value) =>
                  updateCTAField('calendarCalendlyTextColor', value)
                }
                updateCalendarIsShowCalendlyCookieBanner={(value) =>
                  updateCTAField('calendarIsShowCalendlyCookieBanner', value)
                }
                updateCalendarIsShowCalendlyPageDetails={(value) =>
                  updateCTAField('calendarIsShowCalendlyPageDetails', value)
                }
                updateCalendarProvider={(value) =>
                  updateCTAField('calendarProvider', value)
                }
                updateCalendarSchedulingLink={(value) =>
                  updateCTAField('calendarSchedulingLink', value)
                }
              />
            )}
            {actionType !== ActionTypeCTA.CALENDLY && (
              <>
                <hr className="border-gray-200 my-6" />

                <p className="text-gray-800 font-semibold mb-4">Components</p>

                <LabelToggle
                  icon={IconMap.Image01}
                  text="Image"
                  checked={isImageEnabled}
                  onChange={() =>
                    updateCTAField('isImageEnabled', !isImageEnabled)
                  }
                  className="mb-3"
                />
                {isImageEnabled && (
                  <ImageInput
                    imageUrl={imageUrl ?? undefined}
                    isUploading={isUploading}
                    uploadProgress={uploadProgress}
                    isRemoving={isRemoving}
                    onUpdateImage={onUpdateImage}
                    onRemoveImage={onRemoveImage}
                    isLabel={false}
                    fileInputStyles="h-31"
                    className="mb-6"
                  />
                )}
                <LabelToggle
                  icon={IconMap.Type01}
                  text="Title"
                  isRequired
                  checked={true}
                  onChange={() => null}
                  className="mb-3 mt-6"
                  disabled
                />
                <Input
                  className="p-3"
                  heightSize="md"
                  type="text"
                  placeholder="Add title"
                  maxCharacters={TITLE_MAX_LENGTH}
                  isError={!!errors.title}
                  value={title}
                  onChange={({ target: { value } }) => handleChangeTitle(value)}
                />
                <LabelToggle
                  icon={IconMap.AlignLeft}
                  text="Description"
                  checked={isDescriptionEnabled}
                  onChange={() =>
                    updateCTAField(
                      'isDescriptionEnabled',
                      !isDescriptionEnabled
                    )
                  }
                  className="mb-3 mt-6"
                />
                {isDescriptionEnabled && (
                  <RichTextEditor
                    content={description ?? ''}
                    placeholder="Add text…"
                    onUpdate={(content) => handleChangeDescription(content)}
                    maxLength={DESCRIPTION_MAX_LENGTH}
                  />
                )}
                <hr className="border-gray-200 my-6" />
                {actionType === ActionTypeCTA.FORM && (
                  <>
                    <p className="text-gray-800 font-semibold mb-4">
                      Form Fields
                    </p>
                    <LabelToggle
                      icon={IconMap.User03}
                      text="Name"
                      checked={isFormName}
                      onChange={handleChangeIsFormName}
                      className="mb-3"
                    />
                    <LabelToggle
                      icon={IconMap.EmailSymbol}
                      text="Email"
                      checked={true}
                      onChange={() => null}
                      disabled
                      className="mb-3"
                    />
                    <LabelToggle
                      icon={IconMap.Phone}
                      text="Phone"
                      checked={isFormPhone}
                      onChange={handleChangeIsFormPhone}
                      className="mb-3"
                    />
                    <LabelToggle
                      icon={IconMap.MessageDotsCircle}
                      text="Message"
                      checked={isFormMessage}
                      onChange={handleChangeIsFormMessage}
                    />
                    <hr className="border-gray-200 my-6" />
                    <p className="text-gray-800 font-semibold mb-4">Button</p>
                    <Label
                      icon={IconMap.TypeSquare}
                      text="Label"
                      className="mb-3"
                    />
                    <Input
                      maxCharacters={BUTTON_LABEL_MAX_LENGTH}
                      className="p-3"
                      type="text"
                      placeholder="Submit"
                      {...register('buttonLabelFormType')}
                      isError={!!errors.buttonLabelFormType}
                      value={buttonLabelFormType}
                      heightSize="md"
                      onChange={({ target: { value } }) =>
                        handleButtonLabelChange(value)
                      }
                    />
                  </>
                )}
                {actionType === ActionTypeCTA.LINK && (
                  <>
                    <p className="text-gray-800 font-semibold mb-4">Button</p>
                    <Label
                      icon={IconMap.TypeSquare}
                      text="Label"
                      className="mb-3"
                    />
                    <Input
                      maxCharacters={BUTTON_LABEL_MAX_LENGTH}
                      className="p-3"
                      type="text"
                      placeholder="Submit"
                      {...register('buttonLabelLinkType')}
                      isError={!!errors.buttonLabelLinkType}
                      value={buttonLabelLinkType}
                      heightSize="md"
                      onChange={({ target: { value } }) =>
                        handleButtonLabelChange(value)
                      }
                    />
                    <Label
                      icon={IconMap.Link}
                      text="URL"
                      className="mt-4 mb-3"
                      isRequired
                    />
                    <Input
                      className="p-3"
                      type="text"
                      placeholder="e.g. https://example.com/link"
                      isError={!!errors.buttonUrl}
                      heightSize="md"
                      value={buttonUrl}
                      onChange={({ target: { value } }) =>
                        handleChangeCTAButtonUrl(value)
                      }
                    />
                  </>
                )}
                {actionType === ActionTypeCTA.POPUP && (
                  <>
                    <p className="text-gray-800 font-semibold mb-4">Button</p>
                    <Label
                      icon={IconMap.TypeSquare}
                      text="Label"
                      className="mb-3"
                    />
                    <Input
                      maxCharacters={BUTTON_LABEL_MAX_LENGTH}
                      className="p-3"
                      type="text"
                      placeholder="Submit"
                      {...register('buttonLabelPopupType')}
                      isError={!!errors.buttonLabelPopupType}
                      value={buttonLabelPopupType}
                      onChange={({ target: { value } }) =>
                        handleButtonLabelChange(value)
                      }
                    />
                  </>
                )}
              </>
            )}
          </div>
          <EditorSidebarConversionsPanelFooter
            onClose={handleClose}
            addBtnTitle={buttonLabelFormType === null ? 'Add' : 'Save'}
            isAddBtnLoading={updateCTAIsLoading}
          ></EditorSidebarConversionsPanelFooter>
        </form>
      </div>
    </>
  );
};
