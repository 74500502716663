import React from 'react';
import { DistributeTeamLogo } from './DistributeTeamLogo';
import { BrandLogoPicker } from './BrandLogoPicker';
import { Icon } from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';

type Props = {
  companyLogo: string | null;
  distributeTeamLogo: string | null;
  isReadonly: boolean;
  pageId?: string;
};

export const PageBrandingPicker: React.FC<Props> = ({
  companyLogo,
  distributeTeamLogo,
  isReadonly,
  pageId,
}) => {
  return (
    <div className="flex gap-1 z-10 w-33 relative">
      <DistributeTeamLogo
        distributeTeamLogo={distributeTeamLogo}
        isReadonly={isReadonly}
      />
      <div className="w-5 h-5 rounded-full bg-base-white absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center justify-center shadow-sm text-gray-700">
        <Icon glyph={IconMap.SmallPlus} width={12} />
      </div>
      <BrandLogoPicker
        isReadonly={isReadonly}
        pageId={pageId}
        companyLogo={companyLogo}
      />
    </div>
  );
};
