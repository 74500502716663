import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useController, useForm } from 'react-hook-form';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';

import { Page } from '@distribute/shared/types';

import { editorSidebarModel } from '../../../../../features/editor-sidebar';
import { conversionKitModel } from '../../../../../features/conversion-kit';
import { EditorSidebarConversionsPanelFooter } from './EditorSidebarConversionsPanelFooter';
import { LabelToggle } from '../../../../../shared/ui/LabelToggle';
import { IconMap } from '../../../../../shared/sprite';
import { ImageInput, Input } from '../../../../../shared/ui';
import { Label } from './Label';
import { Icon } from '../../../../../shared/ui';
import { RichTextEditor } from '../../../../../entities/tiptap-editor';
import { TemplateExtended } from '@distribute/shared/api-types/templates';
import { useCollaboration } from '../../../../../entities/collaboration';

const TITLE_MAX_LENGTH = 60;
const DESCRIPTION_MAX_LENGTH = 400;
const BUTTON_LABEL_MAX_LENGTH = 40;

const validationSchema = object().shape({
  title: string().max(TITLE_MAX_LENGTH).required(),
  buttonLabel: string().max(BUTTON_LABEL_MAX_LENGTH),
});

export type SqueezePageForm = {
  title: string;
  description: string;
  isFormName: boolean;
  isFormPhone: boolean;
  buttonLabel: string;
};

type Props = {
  currentPage: Page | TemplateExtended;
};

export const CollaborativeEditorSidebarSqueezePagePanel: React.FC<Props> = ({
  currentPage,
}) => {
  const dispatch = useDispatch();

  const { squeezePageData, updateSqueezePageField } = useCollaboration();

  const {
    title,
    description,
    buttonLabel,
    imageUrl,
    isFormName,
    isFormPhone,
    isImageEnabled,
    isDescriptionEnabled,
  } = squeezePageData;
  const squeezePage = useSelector(
    conversionKitModel.selectors.selectSqueezePageWithError
  );
  const updateSqueezePageIsLoading = useSelector(
    conversionKitModel.selectors.selectUpdateSqueezePageIsLoading
  );
  const isUploadingImage = useSelector(
    conversionKitModel.selectors.selectIsUploadImageLoading
  );
  const uploadImageProgress = useSelector(
    conversionKitModel.selectors.selectUploadingSqueezePageImageProgress
  );
  const isRemovingImage = useSelector(
    conversionKitModel.selectors.selectIsRemoveSqueezePageImageLoading
  );

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<SqueezePageForm>({
    resolver: yupResolver(validationSchema),
    defaultValues: useMemo(
      () => ({
        title: title ?? '',
        description: description ?? '',
        isFormName: isFormName,
        isFormPhone: isFormPhone,
        buttonLabel: buttonLabel ?? '',
      }),
      [buttonLabel, description, isFormName, isFormPhone, title]
    ),
  });

  const {
    field: { onChange: onChangeTitle },
  } = useController({
    control,
    name: 'title',
  });

  const handleChangeTitle = (value: string) => {
    onChangeTitle(value);
    updateSqueezePageField('title', value);
  };

  const {
    field: { onChange: onChangeIsFormName },
  } = useController({
    control,
    name: 'isFormName',
  });

  const handleChangeIsFormName = (value: boolean) => {
    onChangeIsFormName(value);
    updateSqueezePageField('isFormName', value);
  };

  const {
    field: { onChange: onChangeIsFormPhone },
  } = useController({
    control,
    name: 'isFormPhone',
  });

  const handleChangeIsFormPhone = (value: boolean) => {
    onChangeIsFormPhone(value);
    updateSqueezePageField('isFormPhone', value);
  };

  const {
    field: { onChange: onChangeDescription },
  } = useController({
    control,
    name: 'description',
  });

  const handleChangeDescription = (value: string) => {
    onChangeDescription(value);
    updateSqueezePageField('description', value);
  };

  const {
    field: { onChange: onChangeButtonLabel },
  } = useController({
    control,
    name: 'buttonLabel',
  });

  const handleChangeButtonLabel = (value: string) => {
    onChangeButtonLabel(value);
    updateSqueezePageField('buttonLabel', value);
  };

  useEffect(() => {
    dispatch(
      conversionKitModel.actions.setSqueezePageEdited({
        ...squeezePage,
        title,
        description: isDescriptionEnabled ? description : null,
        imageUrl: isImageEnabled ? imageUrl : null,
        isFormName,
        isFormPhone,
        buttonLabel,
      })
    );
  }, [
    squeezePage,
    title,
    description,
    isFormName,
    isFormPhone,
    buttonLabel,
    isImageEnabled,
    isDescriptionEnabled,
    dispatch,
    imageUrl,
  ]);

  const handleClose = () => {
    dispatch(editorSidebarModel.actions.setActiveConversionSettingsPanel(null));
    dispatch(conversionKitModel.actions.setSqueezePageEdited(undefined));
  };

  const handleFormSubmit = () => {
    updateSqueezePageField('isActive', true);
    updateSqueezePageField(
      'description',
      isDescriptionEnabled ? description : null
    );
    updateSqueezePageField('buttonLabel', buttonLabel || 'Submit');
    handleClose();
  };

  const onUpdateImage = (image: File) => {
    dispatch(
      conversionKitModel.actions.changeSqueezePageImage({
        documentContentId: currentPage.content.id,
        image,
      })
    );
  };

  const onRemoveImage = () => {
    updateSqueezePageField('imageUrl', null);
  };

  return (
    <>
      <header className="h-29 bg-base-white py-6 px-4 border-b border-gray-200">
        <div
          className="flex gap-2 text-gray-600 text-sm font-semibold cursor-pointer mb-4"
          onClick={handleClose}
        >
          <Icon glyph={IconMap.ArrowLeft} width={20} />
          <span>Back</span>
        </div>
        <h2 className="text-2xl font-display font-medium text-gray-900">
          Squeeze Page
        </h2>
      </header>
      <div className={cn('overflow-y-auto h-[calc(100%-192px)]')}>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="py-6 px-4">
            <p className="text-gray-800 font-semibold">Components</p>

            <LabelToggle
              icon={IconMap.Image01}
              text="Image"
              checked={isImageEnabled}
              onChange={() =>
                updateSqueezePageField('isImageEnabled', !isImageEnabled)
              }
              className="mt-4"
            />
            {isImageEnabled && (
              <ImageInput
                imageUrl={imageUrl ?? ''}
                isUploading={isUploadingImage}
                uploadProgress={uploadImageProgress}
                isRemoving={isRemovingImage}
                onUpdateImage={onUpdateImage}
                onRemoveImage={onRemoveImage}
                isLabel={false}
                fileInputStyles="h-100"
                imageClassName="!h-100 !border !border-gray-300 !bg-image-uploader-bg !bg-cover"
                className="mt-3"
              />
            )}
            <LabelToggle
              checked
              disabled
              isRequired
              text="Title"
              icon={IconMap.Type01}
              onChange={() => null}
              className="mt-6"
            />
            <Input
              {...register('title')}
              className="p-3 mt-3"
              heightSize="md"
              type="text"
              placeholder="Add title"
              maxCharacters={TITLE_MAX_LENGTH}
              isError={!!errors.title}
              value={title ?? ''}
              onChange={({ target: { value } }) => handleChangeTitle(value)}
            />
            <LabelToggle
              icon={IconMap.AlignLeft}
              text="Description"
              checked={isDescriptionEnabled}
              onChange={() =>
                updateSqueezePageField(
                  'isDescriptionEnabled',
                  !isDescriptionEnabled
                )
              }
              className="mt-6 mb-3"
            />
            {isDescriptionEnabled && (
              <RichTextEditor
                content={description ?? ''}
                placeholder="Add text…"
                onUpdate={(content) => handleChangeDescription(content)}
                maxLength={DESCRIPTION_MAX_LENGTH}
              />
            )}

            <hr className="border-gray-200 my-6" />

            <p className="text-gray-800 font-semibold">Form Fields</p>
            <LabelToggle
              icon={IconMap.User03}
              text="Name"
              checked={isFormName}
              onChange={handleChangeIsFormName}
              className="mt-4"
            />
            <LabelToggle
              disabled
              checked
              icon={IconMap.EmailSymbol}
              text="Email"
              onChange={() => null}
              className="mt-3"
            />
            <LabelToggle
              icon={IconMap.Phone}
              text="Phone"
              checked={isFormPhone}
              onChange={handleChangeIsFormPhone}
              className="mt-3"
            />

            <hr className="border-gray-200 my-6" />

            <p className="text-gray-800 font-semibold">Button</p>
            <Label
              icon={IconMap.TypeSquare}
              text="Label"
              className="mt-4 mb-3"
            />
            <Input
              {...register('buttonLabel')}
              maxCharacters={BUTTON_LABEL_MAX_LENGTH}
              className="p-3"
              type="text"
              placeholder="Submit"
              isError={!!errors.buttonLabel}
              heightSize="md"
              value={buttonLabel ?? ''}
              onChange={({ target: { value } }) =>
                handleChangeButtonLabel(value)
              }
            />
          </div>
          <EditorSidebarConversionsPanelFooter
            onClose={handleClose}
            addBtnTitle={buttonLabel === null ? 'Add' : 'Save'}
            isAddBtnLoading={updateSqueezePageIsLoading}
          />
        </form>
      </div>
    </>
  );
};
