import { Snippet } from '@distribute/shared/types';
import { EditorContent, useEditor } from '@tiptap/react';
import { FC } from 'react';
import { getMainEditorExtensions } from '../../mainEditorExtensions';
import { FeatureFlags } from '../../../feature-flag';
import { EditorHtmlContent } from '../../../../pages/editor/ui/components/EditorHtmlContent';
import { environment } from '../../../../environments/environment';

type Props = {
  snippet: Snippet;
};
export const TextSnippetView: FC<Props> = ({ snippet }) => {
  const readonlyEditor = useEditor({
    extensions: getMainEditorExtensions(),
    editorProps: {
      attributes: {
        class:
          'main-editor main-editor-readonly inside-editor snippet-text-readonly',
      },
    },
    content: snippet.content,
    editable: false,
  });

  const isHtmlContentFlow = environment.featureFlags[FeatureFlags.HTML_CONTENT];

  return isHtmlContentFlow ? (
    <EditorHtmlContent htmlContent={snippet.htmlContent} />
  ) : (
    <EditorContent editor={readonlyEditor} />
  );
};
