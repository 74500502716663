import { pagesModel } from '..';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { put, select } from 'redux-saga/effects';
import { pagesApi } from '../../../../shared/api/';
import { Page } from '@distribute/shared/types';

export function* deletePageCoverImage() {
  try {
    const currentPage: Page = yield select(
      pagesModel.selectors.selectCurrentPageWithError
    );

    yield pagesApi.deletePageCoverImage(currentPage.id);
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to change page cover')
      )
    );
  }
}
