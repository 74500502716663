import { useDispatch, useSelector } from 'react-redux';
import { IconMap } from '../../../shared/sprite';
import { Icon, Tooltip } from '../../../shared/ui';
import React, { useCallback, useRef, useState } from 'react';
import { pagesModel } from '../../pages';
import { v4 } from 'uuid';
import { mediaUploadModel } from '../../media-upload';
import { createTypesRegex } from '../../../shared/lib';
import { createNotification, snackbarModel } from '../../snackbar';
import { IMAGE_ACCEPT_STRING, IMAGE_TYPES } from '../../../shared/constants';
import { useCollaboration } from '../../../entities/collaboration';

type Props = {
  companyLogo: string | null;
  isReadonly: boolean;
  pageId?: string;
};

const validationRegexp = createTypesRegex(IMAGE_TYPES, 'image');

export const BrandLogoPicker: React.FC<Props> = ({
  isReadonly,
  pageId,
  companyLogo,
}) => {
  const dispatch = useDispatch();

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [isUploading, setIsUploading] = useState(false);
  const isBrandLogoDeleting = useSelector(
    pagesModel.selectors.selectIsBrandLogoDeleting
  );

  const { isCollaborationEnabled, brandLogo, updateBrandLogo } =
    useCollaboration();

  const logo = isCollaborationEnabled ? brandLogo : companyLogo;

  const onUploaded = useCallback(
    (url: string) => {
      if (!pageId) return;
      if (isCollaborationEnabled) {
        updateBrandLogo(url);
      } else {
        dispatch(
          pagesModel.actions.changeBrandLogo({
            pageId,
            brandLogo: url,
          })
        );
      }

      setTimeout(() => {
        setIsUploading(false);
      }, 200);
    },
    [dispatch, isCollaborationEnabled, pageId, updateBrandLogo]
  );

  const handleUpload = useCallback(
    (file: File) => {
      const newId = v4();
      dispatch(
        mediaUploadModel.actions.uploadFile({
          file,
          id: newId,
          cb: (url) => onUploaded(url),
          isTemplateMode: false,
        })
      );
    },
    [dispatch, onUploaded]
  );

  const onValidateAndUpload = useCallback(
    (file: File | undefined) => {
      if (!file) {
        return;
      }
      const { type } = file;

      if (!validationRegexp.test(type)) {
        dispatch(
          snackbarModel.actions.addNotificationAction(
            createNotification('error', 'File type not allowed')
          )
        );
        return;
      }

      handleUpload(file);
    },
    [handleUpload, dispatch]
  );

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    setIsUploading(true);

    e.target.value = '';

    onValidateAndUpload(file);
  };

  const deletePageCompanyLogo = () => {
    if (!pageId) return;

    if (isCollaborationEnabled) {
      updateBrandLogo(null);
      dispatch(pagesModel.actions.deletePageBrandLogo());
    } else {
      dispatch(
        pagesModel.actions.changeBrandLogo({
          brandLogo: null,
          pageId,
        })
      );
    }
  };

  const uploadCompanyLogo = () => {
    fileInputRef.current?.click();
  };

  return (
    <>
      <Tooltip
        trigger={
          <div
            className="w-16 h-16 relative cursor-pointer group"
            onClick={logo ? deletePageCompanyLogo : uploadCompanyLogo}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="64"
              height="64"
              viewBox="0 0 64 64"
              fill="none"
              className="svg-shadow-sm"
            >
              <mask id="brand-logo-mask" fill="white">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M64 12C64 5.37258 58.6274 0 52 0H10.4332C4.67109 0 0 4.67109 0 10.4332C0 15.8627 4.83508 20.1947 8.27996 24.3914C9.97973 26.4621 11 29.1119 11 32C11 34.8881 9.97973 37.5379 8.27996 39.6086C4.83508 43.8053 0 48.1373 0 53.5668C0 59.3289 4.67109 64 10.4332 64H52C58.6274 64 64 58.6274 64 52V12Z"
                />
              </mask>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M64 12C64 5.37258 58.6274 0 52 0H10.4332C4.67109 0 0 4.67109 0 10.4332C0 15.8627 4.83508 20.1947 8.27996 24.3914C9.97973 26.4621 11 29.1119 11 32C11 34.8881 9.97973 37.5379 8.27996 39.6086C4.83508 43.8053 0 48.1373 0 53.5668C0 59.3289 4.67109 64 10.4332 64H52C58.6274 64 64 58.6274 64 52V12Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M64 12C64 5.37258 58.6274 0 52 0H10.4332C4.67109 0 0 4.67109 0 10.4332C0 15.8627 4.83508 20.1947 8.27996 24.3914C9.97973 26.4621 11 29.1119 11 32C11 34.8881 9.97973 37.5379 8.27996 39.6086C4.83508 43.8053 0 48.1373 0 53.5668C0 59.3289 4.67109 64 10.4332 64H52C58.6274 64 64 58.6274 64 52V12Z"
                fill={logo ? 'url(#pattern2)' : 'white'}
              />
              {logo && !isReadonly && (
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M64 12C64 5.37258 58.6274 0 52 0H10.4332C4.67109 0 0 4.67109 0 10.4332C0 15.8627 4.83508 20.1947 8.27996 24.3914C9.97973 26.4621 11 29.1119 11 32C11 34.8881 9.97973 37.5379 8.27996 39.6086C4.83508 43.8053 0 48.1373 0 53.5668C0 59.3289 4.67109 64 10.4332 64H52C58.6274 64 64 58.6274 64 52V12Z"
                  fill="black"
                  fillOpacity="0"
                  className="branding-svg-overflow"
                />
              )}
              <path
                d="M8.27996 24.3914L7.50702 25.0259L8.27996 24.3914ZM10.4332 1H52V-1H10.4332V1ZM7.50702 25.0259C9.06538 26.9243 10 29.3517 10 32H12C12 28.8721 10.8941 25.9999 9.05291 23.7569L7.50702 25.0259ZM10 32C10 34.6483 9.06538 37.0757 7.50702 38.9741L9.05291 40.2431C10.8941 38.0001 12 35.1279 12 32H10ZM52 63H10.4332V65H52V63ZM63 12V52H65V12H63ZM52 65C59.1797 65 65 59.1797 65 52H63C63 58.0751 58.0751 63 52 63V65ZM7.50702 38.9741C5.86768 40.9712 3.69143 43.2333 2.03548 45.5427C0.346126 47.8987 -1 50.5466 -1 53.5668H1C1 51.1576 2.07141 48.9247 3.66082 46.7081C5.28363 44.445 7.24736 42.4427 9.05291 40.2431L7.50702 38.9741ZM-1 10.4332C-1 13.4534 0.346127 16.1013 2.03548 18.4573C3.69143 20.7667 5.86768 23.0288 7.50702 25.0259L9.05291 23.7569C7.24736 21.5573 5.28363 19.555 3.66082 17.2919C2.07141 15.0753 1 12.8424 1 10.4332H-1ZM52 1C58.0751 1 63 5.92487 63 12H65C65 4.8203 59.1797 -1 52 -1V1ZM-1 53.5668C-1 59.8812 4.11881 65 10.4332 65V63C5.22338 63 1 58.7766 1 53.5668H-1ZM10.4332 -1C4.11881 -1 -1 4.11881 -1 10.4332H1C1 5.22338 5.22338 1 10.4332 1V-1Z"
                fill="black"
                fillOpacity="0.08"
                mask="url(#brand-logo-mask)"
              />
              {logo && (
                <defs>
                  <pattern
                    id="pattern2"
                    patternContentUnits="objectBoundingBox"
                    width="1"
                    height="1"
                  >
                    <use
                      xlinkHref="#brand-logo-image"
                      transform="scale(0.003125)"
                    />
                  </pattern>
                  <image
                    id="brand-logo-image"
                    width="320"
                    height="320"
                    xlinkHref={logo}
                    preserveAspectRatio="xMidYMid slice"
                  />
                </defs>
              )}
            </svg>

            {!logo && !isUploading && (
              <Icon
                glyph={IconMap.ImagePlus}
                width={20}
                className="text-gray-700 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
              />
            )}
            {(isUploading || isBrandLogoDeleting) && (
              <span className="w-5 h-5 flex items-center justify-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                <img
                  src="../../../assets/images/loading.png"
                  alt="Wizard write loading"
                  className="spin"
                />
              </span>
            )}
            {logo && !isBrandLogoDeleting && !isReadonly && (
              <Icon
                glyph={IconMap.Trash}
                width={20}
                className="text-base-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-all pointer-events-none z-100"
              />
            )}
          </div>
        }
        hideArrow
        sideOffset={4}
        {...{ isOpen: isReadonly || logo ? false : undefined }}
      >
        <div className="px-1 py-2">
          <p className="text-xs font-semibold mb-1 text-base-white">
            Your client logo
          </p>
          <p className="text-gray-300">Click the icon to select your </p>
          <p className="text-gray-300">client logo and personalize this</p>
          <p className="text-gray-300">page.</p>
        </div>
      </Tooltip>
      <input
        ref={fileInputRef}
        accept={IMAGE_ACCEPT_STRING}
        type="file"
        onChange={onChange}
        className="appearance-none absolute opacity-0 pointer-events-none left-0 top-0"
      />
    </>
  );
};
