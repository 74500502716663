import React, { useCallback, useEffect } from 'react';
import { subscribePopupModel } from '../model';
import { useDispatch, useSelector } from 'react-redux';

import {
  ActiveConversionSettingsPanel,
  editorSidebarModel,
} from '../../editor-sidebar';

import { ConversionControlItem } from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';
import { narrationModel } from '../../narration';

type IProps = {
  isHidden: boolean;
  isDisabled: boolean;
};

export const SubscribePopup: React.FC<IProps> = ({ isDisabled, isHidden }) => {
  const dispatch = useDispatch();

  const isSubscribeModalOpen = useSelector(
    subscribePopupModel.selectors.selectSubscribePopupIsModalOpen
  );

  const toggleIsSubscribePopupOpen = useCallback(() => {
    dispatch(narrationModel.actions.setPopupOpen(false));
    dispatch(subscribePopupModel.actions.setIsModalOpen(!isSubscribeModalOpen));
  }, [dispatch, isSubscribeModalOpen]);

  const handleOpenPopUpSettings = () => {
    dispatch(narrationModel.actions.setPopupOpen(false));
    dispatch(editorSidebarModel.actions.setSidebarOpen(true));
    dispatch(
      editorSidebarModel.actions.setActiveConversionSettingsPanel(
        ActiveConversionSettingsPanel.POP_UP
      )
    );
  };

  useEffect(() => {
    return () => {
      dispatch(subscribePopupModel.actions.setIsModalOpen(false));
    };
  }, [dispatch]);

  if (isHidden) return null;

  return (
    <ConversionControlItem
      title="Pop-up"
      icon={IconMap.PopupIcon}
      isOpened={isSubscribeModalOpen}
      isDisabled={isDisabled}
      onEdit={handleOpenPopUpSettings}
      onPreview={toggleIsSubscribePopupOpen}
    />
  );
};
