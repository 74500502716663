import React, { useCallback, useEffect, useRef, useState } from 'react';
import { PageCoverButton } from './PageCoverButton';
import cn from 'classnames';
import { PageCoverUploadInput } from './PageCoverUploadInput';
import { useDispatch, useSelector } from 'react-redux';
import { mediaUploadModel } from '../../../../features/media-upload';
import { v4 } from 'uuid';
import { pagesModel } from '../../../../features/pages';
import {
  createNotification,
  snackbarModel,
} from '../../../../features/snackbar';
import { templatesModel } from '../../../../features/templates';
import { createTypesRegex } from '../../../../shared/lib';
import { Button } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';
import { IMAGE_ACCEPT_STRING, IMAGE_TYPES } from '../../../../shared/constants';
import { useCollaboration } from '../../../../entities/collaboration';

type Props = {
  isTemplateMode?: boolean;
};

export const PAGE_COVER_ELEMENT_ID = 'pageCover';

const validationRegexp = createTypesRegex(IMAGE_TYPES, 'image');

export const PageCover: React.FC<Props> = ({ isTemplateMode }) => {
  const dispatch = useDispatch();

  const ref = useRef<HTMLInputElement>(null);

  const [id, setNewId] = useState(v4());

  const uploadFiles = useSelector(mediaUploadModel.selectors.selectFiles);
  const [file, setFile] = useState(uploadFiles.find((i) => i.id === id));

  useEffect(() => {
    setFile(uploadFiles.find((i) => i.id === id));
  }, [id, uploadFiles]);

  const [fileName, setFileName] = useState<string>('');

  useEffect(() => {
    if (file?.status === 'error') {
      setNewId(v4());
    }
  }, [file?.status]);

  const currentPage = useSelector(pagesModel.selectors.selectCurrentPage);

  const currentTemplate = useSelector(
    templatesModel.selectors.selectCurrentTemplate
  );

  const currentPageOrTemplate = isTemplateMode ? currentTemplate : currentPage;

  const [isUploadMenuOpen, setUploadMenuOpen] = useState(
    !isTemplateMode && currentPage?.isBranded
  );

  const setUploadCoverImageMenuOpen = (isOpen: boolean) => {
    dispatch(pagesModel.actions.setIsPageCoverImageMenuOpen(isOpen));
  };

  const setUploadMenuOpenHandler = (isOpen: boolean) => {
    setUploadMenuOpen(isOpen);
    setUploadCoverImageMenuOpen(isOpen);
  };

  const [isUploading, setIsUploading] = useState(false);

  const { isCollaborationEnabled, coverImageUrl, updateCoverImageUrl } =
    useCollaboration();

  useEffect(() => {
    if (file?.status === 'uploading') {
      setIsUploading(true);
    } else if (file?.status === 'error') {
      setIsUploading(false);
    }
  }, [file?.status]);

  const onUploaded = useCallback(
    (url: string) => {
      if (isTemplateMode) {
        if (!currentTemplate) return;
        dispatch(
          templatesModel.actions.changeCoverImageUrl({
            templateId: currentTemplate.id,
            coverImageUrl: url,
          })
        );
      } else {
        if (!currentPage) return;
        if (isCollaborationEnabled) {
          updateCoverImageUrl(url);
        } else {
          dispatch(
            pagesModel.actions.changeCoverImageUrl({
              pageId: currentPage.id,
              coverImageUrl: url,
            })
          );
        }
      }
      setTimeout(() => {
        setIsUploading(false);
      }, 1000);
    },
    [
      isTemplateMode,
      currentTemplate,
      dispatch,
      currentPage,
      isCollaborationEnabled,
      updateCoverImageUrl,
    ]
  );

  const handleUpload = useCallback(
    (file: File) => {
      const newId = v4();
      setNewId(newId);
      dispatch(
        mediaUploadModel.actions.uploadFile({
          file,
          id: newId,
          cb: (url) => onUploaded(url),
          isTemplateMode,
        })
      );
    },
    [dispatch, isTemplateMode, onUploaded]
  );

  const onValidateAndUpload = useCallback(
    (file: File | undefined) => {
      if (!file) {
        return;
      }
      setNewId(v4());
      const { type, name } = file;

      if (!validationRegexp.test(type)) {
        dispatch(
          snackbarModel.actions.addNotificationAction(
            createNotification('error', 'File type not allowed')
          )
        );
        return;
      }

      setFileName(name);
      handleUpload(file);
    },
    [handleUpload, dispatch]
  );

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    e.target.value = '';

    onValidateAndUpload(file);
  };

  const handleDeletePageCover = () => {
    setUploadMenuOpenHandler(false);
    if (isTemplateMode) {
      if (!currentTemplate) return;
      dispatch(
        templatesModel.actions.changeCoverImageUrl({
          coverImageUrl: null,
          templateId: currentTemplate.id,
        })
      );
    } else {
      if (!currentPage) return;
      if (isCollaborationEnabled) {
        updateCoverImageUrl(null);
        dispatch(pagesModel.actions.deletePageCoverImage());
      } else {
        dispatch(
          pagesModel.actions.changeCoverImageUrl({
            coverImageUrl: null,
            pageId: currentPage.id,
          })
        );
      }
    }
  };

  const handleOpenSelectFileMenu = () => {
    ref.current?.click();
  };

  useEffect(() => {
    return () => {
      dispatch(pagesModel.actions.setIsPageCoverImageMenuOpen(false));
    };
  }, [dispatch]);

  const coverUrl =
    isCollaborationEnabled && !isTemplateMode
      ? coverImageUrl
      : currentPageOrTemplate?.content.coverImageUrl;

  return (
    <div
      id={PAGE_COVER_ELEMENT_ID}
      className={cn('transition-all group', {
        'h-16': !isUploadMenuOpen && !coverUrl,
        'h-62.5': isUploadMenuOpen || coverUrl,
      })}
    >
      {coverUrl && !isUploading ? (
        <div
          style={{
            backgroundImage: `url(${coverUrl})`,
          }}
          className="h-full w-full bg-no-repeat bg-center bg-cover relative"
        >
          <div className="absolute top-3.5 right-4 flex items-center gap-2">
            <Button
              variant="icon-text"
              color="secondary"
              size="sm"
              iconLeftName={IconMap.RefreshCcw04}
              iconLeftWidth={20}
              className="font-semibold !px-3"
              iconBlockClassName="mr-1.5"
              onClick={handleOpenSelectFileMenu}
            >
              Replace
            </Button>
            <Button
              variant="icon-text"
              color="secondary"
              size="sm"
              iconLeftName={IconMap.ImageX}
              iconLeftWidth={20}
              className="font-semibold !px-3"
              iconBlockClassName="mr-1.5"
              onClick={handleDeletePageCover}
            >
              Remove cover
            </Button>
          </div>
        </div>
      ) : isUploadMenuOpen || isUploading ? (
        <div className="py-4 px-3.5 bg-gray-75 h-full">
          <PageCoverUploadInput
            allowedTypes={['PNG', 'JPG']}
            maxSize="10 MB"
            onValidateAndUpload={onValidateAndUpload}
            fileName={fileName}
            isUploading={isUploading}
            progress={file?.progress}
            handleDelete={handleDeletePageCover}
            clickHandler={handleOpenSelectFileMenu}
          />
        </div>
      ) : (
        <PageCoverButton onClick={() => setUploadMenuOpenHandler(true)} />
      )}
      <input
        ref={ref}
        accept={IMAGE_ACCEPT_STRING}
        type="file"
        onChange={onChange}
        className="appearance-none absolute opacity-0 pointer-events-none left-0 top-0"
      />
    </div>
  );
};
