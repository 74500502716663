import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { Dropdown, Icon, Tooltip } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useSelector } from 'react-redux';
import { DeleteSnippetConfirmationModal } from '../../../../features/snippets/ui/components/DeleteSnippetConfirmationModal';
import { snippetsModel } from '../../../../features/snippets';

type Props = {
  setIsSidebarOpen: Dispatch<SetStateAction<boolean>>;
};

export const SnippetSettingsDropdown: React.FC<Props> = ({
  setIsSidebarOpen,
}) => {
  const currentSnippet = useSelector(
    snippetsModel.selectors.selectCurrentSnippet
  );

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const templateMenuItems = useMemo(
    () => [
      {
        id: 'Settings',
        label: 'Settings',
        onClick: () => {
          setIsSidebarOpen(true);
        },
        iconName: IconMap.Settings,
        iconWidth: 16,
      },
      {
        id: 'Delete',
        label: 'Delete snippet',
        onClick: () => setIsDeleteModalOpen(true),
        iconName: IconMap.Delete,
        isSeparatedFromTop: true,
      },
    ],
    [setIsSidebarOpen]
  );

  return (
    <>
      <Dropdown
        listStyles="shadow-lg"
        triggerComponent={
          <Tooltip
            trigger={
              <DropdownMenu.Trigger className="rounded-lg flex items-center justify-center focus:outline-none text-gray-600 border border-gray-300 hover:bg-gray-50 h-9 w-9">
                <span className="flex items-center justify-center">
                  <Icon glyph={IconMap.DotsVertical} className="w-5 h-5" />
                </span>
              </DropdownMenu.Trigger>
            }
            sideOffset={4}
          >
            <p className="px-2 py-1 font-semibold text-xs">More actions</p>
          </Tooltip>
        }
        items={templateMenuItems}
        align="start"
        isModalMode={false}
      />
      {currentSnippet && (
        <DeleteSnippetConfirmationModal
          redirectToSnippets
          isOpen={isDeleteModalOpen}
          onClose={() => {
            setIsDeleteModalOpen(false);
          }}
          snippet={currentSnippet}
        />
      )}
    </>
  );
};
