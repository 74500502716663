import * as Popover from '@radix-ui/react-popover';
import { NodeViewContent, NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { useCallback } from 'react';
import EmojiPicker, {
  EmojiClickData,
  EmojiStyle,
  SkinTones,
  Theme,
} from 'emoji-picker-react';
import { categories } from '../../../../features/icon-picker';

export type CalloutNodeType = NodeViewProps['node'] & {
  attrs: {
    emoji: string;
    bgColor: string;
  };
};

export type CalloutNodeViewProps = NodeViewProps & {
  node: CalloutNodeType;
};

type Props = CalloutNodeViewProps & {
  htmlContent?: string;
};

export const CalloutNodeView = ({
  node,
  getPos,
  editor,
  htmlContent,
}: Props) => {
  const onEmojiChange = useCallback(
    ({ emoji }: EmojiClickData) => {
      editor
        ?.chain()
        .focus(getPos() + 1)
        .updateAttributes(node.type.name, { emoji })
        .run();
    },
    [getPos, editor, node]
  );

  return (
    <NodeViewWrapper className="!border-none">
      <div
        data-type="callout"
        className="relative flex gap-2 p-4 rounded-lg flex-start"
        style={{ backgroundColor: node.attrs.bgColor }}
      >
        <div contentEditable={false}>
          <Popover.Root>
            <Popover.Trigger asChild>
              <button className="flex items-center justify-center bg-transparent rounded-lg hover:bg-gray-200 w-8 h-8">
                <span className="font-[sans-serif] text-2xl text-center w-8">
                  {node.attrs.emoji}
                </span>
              </button>
            </Popover.Trigger>
            <Popover.Portal>
              <Popover.Content side="bottom" align="start">
                <EmojiPicker
                  lazyLoadEmojis={true}
                  theme={Theme.LIGHT}
                  skinTonesDisabled
                  previewConfig={{
                    showPreview: false,
                  }}
                  defaultSkinTone={SkinTones.NEUTRAL}
                  emojiVersion="2.0"
                  emojiStyle={EmojiStyle.APPLE}
                  onEmojiClick={onEmojiChange}
                  width={336}
                  height={420}
                  categories={categories}
                />
              </Popover.Content>
            </Popover.Portal>
          </Popover.Root>
        </div>
        <div className="w-full outline-none relative">
          {node.firstChild?.textContent?.trim().length === 0 && (
            <div className="absolute top-0 left-0 -z-10 text-lg text-gray-500 whitespace-nowrap">
              Type something…
            </div>
          )}
          {htmlContent ? (
            <div
              className="whitespace-pre-wrap callout-content"
              data-node-view-content
            >
              <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
            </div>
          ) : (
            <NodeViewContent className="callout-content" />
          )}
        </div>
      </div>
    </NodeViewWrapper>
  );
};
