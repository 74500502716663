import React, { FC } from 'react';
import { CalendarProvider } from '@distribute/shared/types';
import { CalendarPreview, Icon } from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';
import { useCollaboration } from '../../../entities/collaboration';

export const CollaborativeActionBannerCalendar: FC = () => {
  const { ctaData } = useCollaboration();

  const {
    calendarCalendlyBgColor,
    calendarCalendlyButtonColor,
    calendarCalendlyTextColor,
    calendarIsShowCalendlyCookieBanner,
    calendarIsShowCalendlyPageDetails,
    calendarProvider,
    calendarSchedulingLink,
  } = ctaData;

  if (calendarSchedulingLink) {
    return (
      <div className="flex justify-center grow-1 rounded-lg overflow-hidden">
        <CalendarPreview
          calendarProvider={calendarProvider}
          calendarSchedulingLink={calendarSchedulingLink}
          calendarCalendlyBgColor={calendarCalendlyBgColor}
          calendarCalendlyButtonColor={calendarCalendlyButtonColor}
          calendarCalendlyTextColor={calendarCalendlyTextColor}
          calendarIsShowCalendlyCookieBanner={
            calendarIsShowCalendlyCookieBanner
          }
          calendarIsShowCalendlyPageDetails={calendarIsShowCalendlyPageDetails}
          styles={
            calendarProvider === CalendarProvider.CALENDLY
              ? { height: 530, width: 640 }
              : { height: 634 }
          }
        />
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center grow-1 rounded-lg h-54.5 bg-gray-100">
      <Icon
        glyph={
          calendarProvider === CalendarProvider.CALENDLY
            ? IconMap.CalendlyLogo
            : IconMap.ChiliPiperLogo
        }
      />
    </div>
  );
};
