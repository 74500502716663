import { templatesModel } from '..';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { put, select } from 'redux-saga/effects';
import { TemplateExtended } from '@distribute/shared/api-types/templates';
import { templatesApi } from '../../../../shared/api';

export function* changeTemplateCoverImage({
  payload: { coverImageUrl, templateId },
}: ReturnType<typeof templatesModel.actions.changeCoverImageUrl>) {
  const currentTemplate: TemplateExtended = yield select(
    templatesModel.selectors.selectCurrentTemplateWithError
  );
  try {
    if (!coverImageUrl) {
      yield templatesApi.deleteTemplateCoverImage(currentTemplate.id);
    }

    const template: TemplateExtended = yield templatesApi.updateTemplate(
      templateId,
      {
        ...currentTemplate,
        content: { ...currentTemplate.content, coverImageUrl },
      }
    );

    if (!template) {
      throw new Error('updatedTemplate is undefined');
    }
    yield put(templatesModel.actions.setCurrentTemplate(template));
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to change template cover')
      )
    );
  }
}
