import React from 'react';

import {
  isStagingEnvironment,
  isTestEnvironment,
  isTest2Environment,
} from '../../shared/config';
import cn from 'classnames';

export const EnvironmentIndicator = () => {
  return isStagingEnvironment || isTestEnvironment || isTest2Environment ? (
    <p
      className={cn(
        'fixed w-16 text-center inset-x-1/2 -translate-x-1/2 transform top-0 text-xs py-1 rounded',
        {
          'text-error-700 bg-error-100': isStagingEnvironment,
          'text-purple-700 bg-purple-100': isTestEnvironment,
          'text-blue-700 bg-blue-100': isTest2Environment,
        }
      )}
    >
      {isStagingEnvironment && 'Staging'}
      {isTestEnvironment && 'Test'}
      {isTest2Environment && 'Test-2'}
    </p>
  ) : null;
};
