export enum BlockingMethodType {
  REMOVAL = 'removal',
  OBSCURATION = 'obscuration',
}

export type GatedContent = {
  id: string;
  isActive: boolean;
  title: string;
  description?: string;
  isFormName: boolean;
  isFormPhone: boolean;
  buttonLabelFormType?: string;
  blockingMethod: BlockingMethodType;
  pagePercentage?: number;
};

export type CollaborativeGatedContent = GatedContent & {
  isDescriptionEnabled: boolean;
};
