import { FC, useState } from 'react';
import cn from 'classnames';
import { NodeViewContent, NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { IconMap } from '../../../../shared/sprite';
import { Icon } from '../../../../shared/ui';

export type IProps = NodeViewProps;

export const ToggleListItemNodeView: FC<IProps> = ({
  editor,
  node,
  getPos,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);

    if (!isOpen) {
      editor.commands.onOpenToggleListItem(node, getPos() + node.nodeSize - 1);
    }
  };

  return (
    <NodeViewWrapper
      className="!border-none toggle-list-item"
      data-opened={isOpen}
    >
      <div className="flex items-start gap-2 pointer-events-none">
        <button
          className="mt-0.75 p-0.5 rounded-1.25 hover:bg-gray-100 pointer-events-auto"
          onClick={handleOpen}
        >
          <Icon
            glyph={IconMap.ToggleIcon}
            width={20}
            className={cn('text-gray-700', { 'rotate-90': isOpen })}
          />
        </button>
        <div className="grow-1 relative">
          {node.firstChild?.content.size === 0 && (
            <div className="absolute top-0 left-0 -z-10 text-lg text-gray-400 whitespace-nowrap">
              Toggle list
            </div>
          )}
          <NodeViewContent className="toggle-list-item-node-view pointer-events-auto" />
        </div>
      </div>
    </NodeViewWrapper>
  );
};
