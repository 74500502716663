import React, { FC, ReactNode } from 'react';
import { CalendarProvider } from '@distribute/shared/types';
import { ColorPicker, Dropdown, Icon, Input } from '../../../../../shared/ui';
import {
  CALENDAR_PROVIDERS,
  CALENDLY_HOW_TO_GET_LINK_URL,
  CHILI_PIPER_HOW_TO_GET_LINK_URL,
} from '../../../lib';
import {
  Control,
  FieldErrors,
  UseFormRegister,
  useController,
} from 'react-hook-form';
import { IconMap } from '../../../../../shared/sprite';
import { LabelToggle } from '../../../../../shared/ui';

export type CollaborativeCalendarForm = {
  calendarProvider: CalendarProvider;
  calendarSchedulingLink: string;
  calendarIsShowCalendlyPageDetails: boolean;
  calendarIsShowCalendlyCookieBanner: boolean;
  calendarCalendlyTextColor: string;
  calendarCalendlyButtonColor: string;
  calendarCalendlyBgColor: string;
};

type IProps = {
  source: 'popup' | 'cta';
  register: UseFormRegister<any>;
  control: Control<any>;
  errors: FieldErrors<any>;
  popupTitleAndDescriptionFields?: ReactNode;
  calendarProvider: CalendarProvider | null;
  calendarIsShowCalendlyPageDetails: boolean;
  calendarIsShowCalendlyCookieBanner: boolean;
  calendarCalendlyTextColor: string;
  calendarCalendlyButtonColor: string;
  calendarCalendlyBgColor: string;
  calendarSchedulingLink: string | null;
  updateCalendarProvider: (value: CalendarProvider) => void;
  updateCalendarIsShowCalendlyPageDetails: (value: boolean) => void;
  updateCalendarIsShowCalendlyCookieBanner: (value: boolean) => void;
  updateCalendarCalendlyTextColor: (value: string) => void;
  updateCalendarCalendlyButtonColor: (value: string) => void;
  updateCalendarCalendlyBgColor: (value: string) => void;
  updateCalendarSchedulingLink: (value: string | null) => void;
};

export const CollaborativeCalendarForm: FC<IProps> = ({
  source,
  popupTitleAndDescriptionFields,
  register: registerBase,
  control: controlBase,
  errors: errorsBase,
  calendarProvider,
  calendarCalendlyBgColor,
  calendarCalendlyButtonColor,
  calendarCalendlyTextColor,
  calendarIsShowCalendlyCookieBanner,
  calendarIsShowCalendlyPageDetails,
  calendarSchedulingLink,
  updateCalendarCalendlyBgColor,
  updateCalendarCalendlyButtonColor,
  updateCalendarCalendlyTextColor,
  updateCalendarIsShowCalendlyCookieBanner,
  updateCalendarIsShowCalendlyPageDetails,
  updateCalendarProvider,
  updateCalendarSchedulingLink,
}) => {
  const control = controlBase as Control<CollaborativeCalendarForm>;
  const register = registerBase as UseFormRegister<CollaborativeCalendarForm>;
  const errors = errorsBase as FieldErrors<CollaborativeCalendarForm>;

  const {
    field: { onChange: changeProvider },
  } = useController({
    control,
    name: 'calendarProvider',
  });

  const handleChangeCalendarProvider = (value: CalendarProvider) => {
    changeProvider(value);
    updateCalendarProvider(value);
  };

  const {
    field: { onChange: changeIsShowCalendlyPageDetails },
  } = useController({
    control,
    name: 'calendarIsShowCalendlyPageDetails',
  });

  const handleChangeIsShowCalendlyPageDetails = (value: boolean) => {
    changeIsShowCalendlyPageDetails(value);
    updateCalendarIsShowCalendlyPageDetails(value);
  };

  const {
    field: { onChange: changeIsShowCalendlyCookieBanner },
  } = useController({
    control,
    name: 'calendarIsShowCalendlyCookieBanner',
  });

  const handleChangeIsShowCalendlyCookieBanner = (value: boolean) => {
    changeIsShowCalendlyCookieBanner(value);
    updateCalendarIsShowCalendlyCookieBanner(value);
  };

  const {
    field: { onChange: changeCalendlyTextColor },
  } = useController({
    control,
    name: 'calendarCalendlyTextColor',
  });

  const handleChangeCalendlyTextColor = (value: string) => {
    changeCalendlyTextColor(value);
    updateCalendarCalendlyTextColor(value);
  };

  const {
    field: { onChange: changeCalendlyButtonColor },
  } = useController({
    control,
    name: 'calendarCalendlyButtonColor',
  });

  const handleChangeCalendlyButtonColor = (value: string) => {
    changeCalendlyButtonColor(value);
    updateCalendarCalendlyButtonColor(value);
  };

  const {
    field: { onChange: changeCalendlyBgColor },
  } = useController({
    control,
    name: 'calendarCalendlyBgColor',
  });

  const handleChangeCalendlyBgColor = (value: string) => {
    changeCalendlyBgColor(value);
    updateCalendarCalendlyBgColor(value);
  };

  const {
    field: { onChange: changeCalendarSchedulingLink },
  } = useController({
    control,
    name: 'calendarSchedulingLink',
  });

  const handleChangeCalendarSchedulingLink = (value: string) => {
    changeCalendarSchedulingLink(value);
    updateCalendarSchedulingLink(value);
  };

  return (
    <div>
      <Dropdown
        items={CALENDAR_PROVIDERS}
        isModalMode={false}
        align="start"
        listStyles="!w-[var(--radix-dropdown-menu-trigger-width)]"
        triggerStyles="bg-base-white mt-3"
        currentItemId={calendarProvider as string}
        onItemChange={(value) => {
          handleChangeCalendarProvider(value as CalendarProvider);
        }}
      />
      <hr className="border-gray-200 my-6" />
      <p className="text-gray-800 font-semibold mb-4">
        {calendarProvider === CalendarProvider.CALENDLY
          ? 'Scheduling link'
          : 'Booking link'}
      </p>
      <Input
        {...register('calendarSchedulingLink')}
        className="p-3"
        heightSize="md"
        type="text"
        placeholder="Input link"
        isError={!!errors.calendarSchedulingLink}
        value={calendarSchedulingLink ?? ''}
        onChange={({ target: { value } }) =>
          handleChangeCalendarSchedulingLink(value)
        }
      />
      <a
        href={
          calendarProvider === CalendarProvider.CALENDLY
            ? CALENDLY_HOW_TO_GET_LINK_URL
            : CHILI_PIPER_HOW_TO_GET_LINK_URL
        }
        target="_blank"
        className="flex items-center gap-x-1 text-primary-700 text-sm font-medium mt-1.5"
        rel="noreferrer"
      >
        {calendarProvider === CalendarProvider.CALENDLY
          ? 'How to get your calendly scheduling link'
          : 'How to get Chili Piper booking link'}
        <Icon glyph={IconMap.ArrowSquareUpRight} width={16} />
      </a>
      {source === 'popup' && (
        <>
          <hr className="border-gray-200 my-6" />
          <p className="text-gray-800 font-semibold">Pop-up Components</p>
          {popupTitleAndDescriptionFields}
        </>
      )}
      {calendarProvider === CalendarProvider.CALENDLY && (
        <>
          <hr className="border-gray-200 my-6" />
          <p className="text-gray-800 font-semibold">Calendly customisation</p>
          <LabelToggle
            icon={IconMap.Menu02}
            text="Page Details"
            checked={calendarIsShowCalendlyPageDetails}
            onChange={() =>
              handleChangeIsShowCalendlyPageDetails(
                !calendarIsShowCalendlyPageDetails
              )
            }
            className="mt-6"
          />
          <LabelToggle
            icon={IconMap.LayoutBottom}
            text="Cookie Banner"
            checked={calendarIsShowCalendlyCookieBanner}
            onChange={() =>
              handleChangeIsShowCalendlyCookieBanner(
                !calendarIsShowCalendlyCookieBanner
              )
            }
            className="mt-6"
          />
          <hr className="border-gray-200 my-6" />
          <div className="flex flex-wrap gap-x-4 gap-y-6">
            <div className="flex flex-col w-[calc(50%-8px)]">
              <span className="text-gray-700 text-sm font-medium">
                Text Color
              </span>
              <ColorPicker
                value={calendarCalendlyTextColor}
                onChange={(color) => handleChangeCalendlyTextColor(color)}
              />
            </div>
            <div className="flex flex-col w-[calc(50%-8px)]">
              <span className="text-gray-700 text-sm font-medium">
                Button & Link Color
              </span>
              <ColorPicker
                value={calendarCalendlyButtonColor}
                onChange={(color) => handleChangeCalendlyButtonColor(color)}
              />
            </div>
            <div className="flex flex-col w-[calc(50%-8px)]">
              <span className="text-gray-700 text-sm font-medium">
                Background Color
              </span>
              <ColorPicker
                value={calendarCalendlyBgColor}
                onChange={(color) => handleChangeCalendlyBgColor(color)}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
