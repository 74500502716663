import React, { useState } from 'react';
import EmojiPicker, {
  EmojiClickData,
  EmojiStyle,
  Emoji,
  SkinTones,
  Theme,
} from 'emoji-picker-react';
import { Trigger } from '@radix-ui/react-popover';
import { Popover } from '../../../shared/ui';
import { useDispatch, useSelector } from 'react-redux';
import { pagesModel } from '../../../features/pages';
import { Page } from '@distribute/shared/types';
import { IconLoader } from './components';
import { TemplateExtended } from '@distribute/shared/api-types/templates';
import { templatesModel } from '../../templates';
import { categories } from '../config';
import { useCollaboration } from '../../../entities/collaboration';

type Props = {
  currentPage: Page | TemplateExtended;
  isTemplateEditor?: boolean;
};

export const IconPicker: React.FC<Props> = ({
  currentPage,
  isTemplateEditor,
}) => {
  const dispatch = useDispatch();
  const [isIconPickerOpen, setIsIconPickerOpen] = useState<boolean>(false);

  const isLoading = useSelector(
    pagesModel.selectors.selectChangePageIconIsLoading
  );
  const handleClickCancel = () => {
    setIsIconPickerOpen(false);
  };

  const { isCollaborationEnabled, icon, updateIcon } = useCollaboration();

  const handleEmojiClick = (emoji: EmojiClickData) => {
    emoji.getImageUrl(EmojiStyle.APPLE);
    if (isTemplateEditor) {
      const currentTemplate = currentPage as TemplateExtended;
      dispatch(
        templatesModel.actions.setCurrentTemplate({
          ...currentTemplate,
          content: { ...currentTemplate.content, icon: emoji.unified },
        })
      );
      handleClickCancel();
    } else {
      if (isCollaborationEnabled) {
        updateIcon(emoji.unified);
        handleClickCancel();
      } else {
        dispatch(
          pagesModel.actions.changePageIcon({
            pageId: (currentPage as Page).id,
            icon: emoji.unified,
            callback: () => handleClickCancel(),
          })
        );
      }
    }
  };

  return (
    <div>
      <Popover
        align="start"
        handleClickCancel={handleClickCancel}
        open={isIconPickerOpen}
        sideOffset={5}
        contentStyles="z-50"
        triggerComponent={
          <Trigger
            onClick={() => setIsIconPickerOpen(true)}
            className="bg-base-white w-16 h-16 flex items-center justify-center whitespace-nowrap border border-gray-100 rounded-xl shadow-sm active:border-gray-100 relative"
          >
            {isLoading ? (
              <IconLoader />
            ) : (
              <Emoji
                unified={
                  isTemplateEditor
                    ? currentPage.content.icon
                    : isCollaborationEnabled
                    ? icon
                    : currentPage.content.icon
                }
                size={20}
              />
            )}
          </Trigger>
        }
      >
        <div className="sm:hidden">
          <EmojiPicker
            lazyLoadEmojis={true}
            theme={Theme.LIGHT}
            skinTonesDisabled
            previewConfig={{
              showPreview: false,
            }}
            defaultSkinTone={SkinTones.NEUTRAL}
            emojiVersion="2.0"
            emojiStyle={EmojiStyle.APPLE}
            onEmojiClick={handleEmojiClick}
            categories={categories}
          />
        </div>
      </Popover>
    </div>
  );
};
