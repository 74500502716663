import { NodeViewContent, NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import cn from 'classnames';
import { Button, Icon } from '../../../../../src/shared/ui';
import { IconMap } from '../../../../../src/shared/sprite';
import { formatDate, formatDateDiff } from '@distribute/shared/utils';
import Calendar from 'react-calendar';
import { RefObject, useCallback, useEffect, useRef, useState } from 'react';

export type TimelineItemNodeType = NodeViewProps['node'] & {
  attrs: {
    progressIcon: string;
    readyIcon: string;
    dueDate: string;
    isDone: boolean;
  };
};

export type TimelineItemNodeViewProps = NodeViewProps & {
  node: TimelineItemNodeType;
};

export const TimelineItemNodeView = ({
  node,
  updateAttributes,
  getPos,
  editor,
}: TimelineItemNodeViewProps) => {
  const isHeadingText =
    node.firstChild?.textContent ||
    node.firstChild?.content.firstChild?.attrs?.href;
  const isDescText =
    node.lastChild?.textContent ||
    node.lastChild?.content.firstChild?.attrs?.href;

  const currentDueDate = node?.attrs.dueDate ?? new Date();
  const dueDate = node.attrs.dueDate ? formatDate(node.attrs.dueDate) : '';
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const handleDueDateChange = useCallback(
    (value: Date | null | (Date | null)[]) => {
      updateAttributes({
        dueDate: value?.toString() ?? '',
      });
    },
    [editor]
  );

  const handleClearDueDate = useCallback(() => {
    updateAttributes({
      dueDate: '',
    });
    setIsCalendarOpen(false);
  }, []);

  const useOutsideAlerter = (ref: RefObject<HTMLElement>) => {
    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          setIsCalendarOpen(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  const calendarRef = useRef(null);

  useOutsideAlerter(calendarRef);

  return (
    <NodeViewWrapper draggable="true" data-drag-handle className="!border-none">
      <div
        data-type="timelineItem"
        className="relative flex gap-6 px-3 pt-4 pb-6 cursor-pointer select-none flex-start rounded-xl hover:bg-base-gray-5 group"
      >
        <div className="absolute left-0 z-[16] items-center justify-center hidden border border-gray-200 rounded-md shadow-xs top-7 group-hover:flex w-7 h-7 bg-base-white timeline__drag-icon">
          <Icon glyph={IconMap.Drag} className="text-gray-700" width={20} />
        </div>
        <button
          onClick={() => {
            updateAttributes({
              isDone: !node.attrs.isDone,
            });
          }}
          className={cn(
            'flex items-center justify-center border rounded-full border-base-black/10 h-14 w-14 min-w-14 z-[15]',
            {
              'bg-gray-50': !node.attrs.isDone,
              'bg-success-100': node.attrs.isDone,
            }
          )}
        >
          <span className="font-[sans-serif] text-xl text-center">
            {node.attrs.isDone ? node.attrs.readyIcon : node.attrs.progressIcon}
          </span>
        </button>
        <div className="relative w-full outline-none cursor-auto">
          {!isHeadingText && (
            <div
              contentEditable={false}
              className="absolute top-0 left-0 text-xl font-bold text-gray-500 -z-10 group-hover:text-base-black"
            >
              Phase Title
            </div>
          )}
          {!isDescText && (
            <div
              contentEditable={false}
              className="absolute bottom-0 left-0 text-lg text-gray-500 group-hover:text-base-black -z-10"
            >
              Use this section to add a short description.
            </div>
          )}
          <NodeViewContent contentEditable={true} className="outline-none" />
        </div>
        <div className="relative">
          <button
            className="flex flex-col items-end"
            onClick={() => {
              setIsCalendarOpen((prevOpen) => !prevOpen);
            }}
          >
            {dueDate ? (
              <span className="font-semibold text-md text-primary-600">
                {dueDate}
              </span>
            ) : (
              <span className="text-gray-500 text-md whitespace-nowrap">
                No due date
              </span>
            )}
            {node.attrs.dueDate && (
              <span className="text-sm text-gray-400">
                {formatDateDiff(new Date(currentDueDate))}
              </span>
            )}
          </button>
          {isCalendarOpen && (
            <div
              ref={calendarRef}
              className="absolute px-6 pt-5 pb-4 border border-gray-200 z-[10000] -top-4 right-24 w-82 bg-base-white rounded-xl"
              onClick={() => {
                return;
              }}
            >
              <Calendar
                allowPartialRange={false}
                value={currentDueDate}
                onChange={handleDueDateChange}
              />
              <div className="mt-5 shadow-xs">
                <Button
                  fullWidth
                  variant="text"
                  onClick={handleClearDueDate}
                  color="secondary"
                  disabled={!node.attrs.dueDate}
                >
                  Clear date
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </NodeViewWrapper>
  );
};
