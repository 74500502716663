import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useCollaboration } from '../../../../entities/collaboration';
import { Icon } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';
import { useSelector } from 'react-redux';
import { pagesModel } from '../../../../features/pages';

type Props = {
  isTemplateMode: boolean;
};

export const PageStatusInfo: React.FC<Props> = ({ isTemplateMode }) => {
  const { isCollaborationEnabled, isPageSaved } = useCollaboration();

  const isSoloMode = isTemplateMode || !isCollaborationEnabled;

  const currentPageIsError = useSelector(
    pagesModel.selectors.selectCurrentPageIsError
  );
  const currentPageChanging = useSelector(
    pagesModel.selectors.selectChangePageContentIsLoading
  );

  const [status, setStatus] = useState('Changes Saved');

  useEffect(() => {
    currentPageChanging && setStatus('Changes Saving');
    currentPageIsError && setStatus('Trying to connect');
    !currentPageIsError && !currentPageChanging && setStatus('Changes Saved');
  }, [currentPageChanging, currentPageIsError]);

  return (
    <div
      className={cn(
        'flex items-center gap-1 sm:pl-2 h-8 text-gray-600 sm:hidden',
        {
          hidden: isTemplateMode,
        }
      )}
    >
      {isSoloMode ? (
        <>
          {!currentPageChanging && (
            <Icon
              width={12}
              glyph={
                currentPageIsError ? IconMap.CloudOffIcon : IconMap.Checked
              }
              className="shrink-0"
            />
          )}
          <p
            className={cn('text-s lg:hidden shrink-0', {
              'text-warning-600': currentPageIsError,
            })}
          >
            {status}
          </p>
        </>
      ) : (
        <>
          <Icon
            width={isPageSaved ? 12 : 16}
            glyph={isPageSaved ? IconMap.Checked : IconMap.InfoCircle}
            className={cn('shrink-0', {
              'text-warning-600': !isPageSaved,
            })}
          />
          <p
            className={cn('text-s lg:hidden shrink-0', {
              'text-warning-600': !isPageSaved,
            })}
          >
            {isPageSaved ? 'Changes Saved' : 'Changes Not Saved'}
          </p>
        </>
      )}
    </div>
  );
};
